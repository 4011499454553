<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/fontawesome.min.css" integrity="sha512-RvQxwf+3zJuNwl4e0sZjQeX7kUa3o82bDETpgVCH2RiwYSZVDdFJ7N/woNigN/ldyOOoKw8584jM4plQdt8bhA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css" integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A==" crossorigin="anonymous" referrerpolicy="no-referrer" />

<body>
  <div class="fullscreen-container">
    <div class="login-container">
      <div class="div" style="display:flex ; justify-content:center; margin-top: -20px;">
        <img  src="../../../assets/images/edyou-r.png" width="140px">
        </div>
      <h3 class="header">Login</h3>
      <form class="form" [formGroup]="form">
        <div class="input-group">
          <!-- <label for="username">Email</label> -->
          <input type="text" id="username" name="username" formControlName="email" name="email" placeholder="Email">
          <div  *ngIf="email.invalid && (email.dirty || email.touched)"
          class="label__error border__error">
          <div *ngIf="email.errors?.['required']">
              Email is required
          </div>
          <div *ngIf="email.errors?.['pattern']">
              Invalid Email

          </div>
</div>
        </div>
        <!-- <div class="input-group">
        
          <input type="password" id="password" name="password" formControlName="password" name="password" placeholder="Password">
          <div  *ngIf="password.invalid && (password.dirty || password.touched)"
          class="label__error border__error">
          <div *ngIf="password.errors?.['required']">
              Password is required
          </div>
     
      </div>
        </div> -->
        <button class="button" (click)="login()"><img class="" style="width : 24px; color:white;" *ngIf="isLoading"
            src="../../assets/Rolling.gif"> <span 
            *ngIf="!isLoading">Login</span></button>
            <br>
            <div style="color: red;" class="error" *ngIf="isOpen">
                <span class="label__error border__error d-flex justify-content-center"> {{err}}</span>
            </div>
      </form>
      
     
      
    </div>
  </div>
</body>
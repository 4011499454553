import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  show: boolean = false;
  isLoading: boolean = false;
  submitted = false;
  err: any;
  isOpen: boolean = false;
  newEmail: any;
  newPassword: any;
  IPv4: any;
  modalPwaPlatform: any;
  linkURL: any = "";

  constructor(private router: Router, private auth: AuthService, private actRouter: ActivatedRoute,) { }

  ngOnInit(): void {
    const terms = localStorage.getItem('Termscondition')
    this.auth.getIpAdress().subscribe((f: any) => {
      this.IPv4 = f.IPv4
      localStorage.setItem('IPAdress', this.IPv4)
    })
    // query param routes to read email and password 
    this.actRouter.queryParams.subscribe((queryParams) => {
      this.newEmail = queryParams['email'] || null;
      var newPassword = queryParams['password'] || null;
      if (newPassword) {
        this.newPassword = newPassword.split("___")
      }
    })
    if (this.newEmail !== null) {
      this.form.get('email')?.setValue(this.newEmail);
      this.form.get('password')?.setValue(this.newPassword[0]);
    }


  }


  // form group
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    password: new FormControl('', Validators.required),
    policy: new FormControl('', Validators.required),
  })


  // validation  controls for form 
  get email() {
    return this.form.controls['email'];
  }

  get password() {
    return this.form.controls['password'];
  }

  get policy() {
    return this.form.controls['policy'];
  }




  // select unselect term & condition checkbox function
  powerButton(e: any) {
    if (e.target.checked == true) {
      localStorage.setItem('Termscondition', 'true')
    }
    else if (e.target.checked == false) {
      this.form.get('policy')?.setValue("");
      localStorage.setItem('Termscondition', 'false')
    }
  }

 /**
 * Validates all form fields within a FormGroup.
 * This function iterates through all form controls in the specified FormGroup and marks them as touched to trigger validation.
 * @param {FormGroup} formGroup - The FormGroup containing form controls to be validated.
 * @returns {void}
 */
  validateAllFormFields(formGroup: FormGroup) {
    // Iterate over each field in the form group
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      // Check if the control is an individual form control
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }



  /**
   * * This function verifies the user's credentials and grants access if they are valid.
   *  It may involve checking the username/password against a database or an authentication service.
   * Returns a success message or throws an error if authentication fails.
   */
  login() {
    this.submitted = true;
    if (this.form.valid) {
      // If the form is valid, initiate the login  request
      let loginPayload = {
        "email": this.form.value.email,
        "password": this.form.value.password,
        "policy": this.form.value.policy,
        "Ipaddress": this.IPv4
      }
      this.isLoading = true
      this.auth.signIn(loginPayload).subscribe(
        (data) => {
          this.isLoading = false
          if (data.statusCode == 200) {
            // If the request is successful, display a success message and reset the form
            localStorage.setItem("user", JSON.stringify(data.data));
            //  console.log(data.data)
            localStorage.setItem("token", JSON.stringify(data.Token));
            this.router.navigate(['/user/dashboard'])
          } else {
            // If there is an error with the request, display an error message
            this.statusCodeError(data)
          }
        })
    } else {
      // If the form is invalid, trigger validation messages for all fields
      this.validateAllFormFields(this.form);
    }

  }

 /**
 * Initiates the Microsoft Single Sign-On (SSO) login process.
 * to log in using their Microsoft accounts, utilizing the Single Sign-On (SSO) mechanism.
 * It may involve redirecting the user to Microsoft's authentication service,
 */
  microsoftSSOLogin() {
    let microsoftLoginUrl = "https://login.microsoftonline.com/"
    const stateParameter = environment.ssoStateParameter;
    // Construct the final URL with the state parameter
    const finalUrl = `${microsoftLoginUrl}?state=${stateParameter}`;
    window.location.href = finalUrl;

  }


  /**
   * Handles errors based on status codes received from an API or server response.
   * based on the received status code, such as displaying a specific error message,
   * @param data 
   */
  statusCodeError(data: any) {
    // Check if the status code is 202, 401, or 402
    if (data.statusCode == 202 || data.statusCode == 401 || data.statusCode == 402) {
      this.err = data.body;
      this.isOpen = true
      setTimeout(() => {
        this.isOpen = false
      }, 5000)
      this.isLoading = false;
      this.router.navigate(['/']);
    }
  }

  customerLogin() {
    this.router.navigate(['user'])
  }

  bookDemoLogin() {
    this.router.navigate(['bookDemo'])
  }

  //navigate to signUp module
  signUp() {
    this.router.navigate(['signUp'])
  }

  //naviagte to forget password module
  forgetPassword() {
    this.router.navigate(['forgetPassword'])
  }

  // password show and hide function
  hideShow() {
    this.show = !this.show
  }
}




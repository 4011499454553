import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';
declare var $: any;


@Component({
  selector: 'app-helppage',
  templateUrl: './helppage.component.html',
  styleUrls: ['./helppage.component.css']
})
export class HelppageComponent implements OnInit {
  accod1: boolean = false;
  accod2: boolean = false ;
  AvatarName: string = 'Hannah'
  //AvatarName: string = 'Nova'

  constructor(private elementRef: ElementRef, private renderer: Renderer2,) { }

  ngOnInit(): void {
    this.AvatarName = environment.Avatar
  }

  tab1() {
    const basetab11 = this.elementRef.nativeElement.querySelector('#base-tab11');
    const basetab22 = this.elementRef.nativeElement.querySelector('#base-tab22');
    const basetab33 = this.elementRef.nativeElement.querySelector('#base-tab33');
    const tab11 = this.elementRef.nativeElement.querySelector('#tab11');
    const tab22 = this.elementRef.nativeElement.querySelector('#tab22');
    const tab33 = this.elementRef.nativeElement.querySelector('#tab33');

    this.renderer.addClass(basetab11, 'active');
    this.renderer.removeClass(basetab22, 'active');
    this.renderer.removeClass(basetab33, 'active');
    this.renderer.setStyle(tab11, 'display', 'table');
    this.renderer.setStyle(tab22, 'display', 'none');
    this.renderer.setStyle(tab33, 'display', 'none');
  }

  tab2() {
    const basetab11 = this.elementRef.nativeElement.querySelector('#base-tab11');
    const basetab22 = this.elementRef.nativeElement.querySelector('#base-tab22');
    const basetab33 = this.elementRef.nativeElement.querySelector('#base-tab33');
    const tab11 = this.elementRef.nativeElement.querySelector('#tab11');
    const tab22 = this.elementRef.nativeElement.querySelector('#tab22');
    const tab33 = this.elementRef.nativeElement.querySelector('#tab33');

    this.renderer.removeClass(basetab11, 'active');
    this.renderer.addClass(basetab22, 'active');
    this.renderer.removeClass(basetab33, 'active');
    this.renderer.setStyle(tab11, 'display', 'none');
    this.renderer.setStyle(tab22, 'display', 'table');
    this.renderer.setStyle(tab33, 'display', 'none');
  }

  tab3() {
    const basetab11 = this.elementRef.nativeElement.querySelector('#base-tab11');
    const basetab22 = this.elementRef.nativeElement.querySelector('#base-tab22');
    const basetab33 = this.elementRef.nativeElement.querySelector('#base-tab33');
    const tab11 = this.elementRef.nativeElement.querySelector('#tab11');
    const tab22 = this.elementRef.nativeElement.querySelector('#tab22');
    const tab33 = this.elementRef.nativeElement.querySelector('#tab33');

    this.renderer.removeClass(basetab11, 'active');
    this.renderer.removeClass(basetab22, 'active');
    this.renderer.addClass(basetab33, 'active');
    this.renderer.setStyle(tab11, 'display', 'none');
    this.renderer.setStyle(tab22, 'display', 'none');
    this.renderer.setStyle(tab33, 'display', 'table');
  }


}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  show: boolean = false;
  step: any = 1;
  isLoading: boolean = false
  countryName: any = [];
  err2: string = '';
  isOpen2: boolean = false;
  isLoading2: boolean = false;
  err: any;
  isOpen: boolean = false;

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit(): void {
    this.countryNames()
  }

  //form 
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    password: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    contact: new FormControl('', Validators.pattern(/^[0-9 ()+-]+$/)),
    school: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    Dob: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zip: new FormControl('', Validators.required),
  });



  // validation 
  get email() {
    return this.form.controls['email'];
  }

  get first_name() {
    return this.form.controls['first_name'];
  }
  get last_name() {
    return this.form.controls['last_name'];
  }
  get Dob() {
    return this.form.controls['Dob'];
  }
  get country() {
    return this.form.controls['country'];
  }
  get state() {
    return this.form.controls['state'];
  }
  get school() {
    return this.form.controls['school'];
  }
  get zip() {
    return this.form.controls['zip'];
  }
  get contact() {
    return this.form.controls['contact'];
  }
  get gender() {
    return this.form.controls['gender'];
  }
  get password() {
    return this.form.controls['password'];
  }

  // next button function to move to next form page
  next() {
    this.step = this.step + 1;
  }

  // previous button function to move back to form page
  previous() {
    this.step = this.step - 1;
    console.log(this.step)
    if (this.step == '0') {
      this.router.navigate(['/'])
    }
  }


  showHide() {
    this.show = !this.show
  }

  /**
   * Validates all form fields within a FormGroup.
   * This function iterates through all form controls in the specified FormGroup and marks them as touched to trigger validation.
   * @param {FormGroup} formGroup - The FormGroup containing form controls to be validated.
   * @returns {void}
   */
  validateAllFormFields(formGroup: FormGroup) {
    // Iterate over each field in the form group
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      // Check if the control is an individual form control
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
  * Initiates the user registration process.
  * This function is responsible for triggering actions and logic related to user registration.
  * @returns {void}
  */
  register() {
    if (this.form.valid) {
      // If the form is valid, initiate the signup  request
      let signUpPayload = {
        "f_name": this.form.value.first_name,
        "l_name": this.form.value.last_name,
        "email": this.form.value.email,
        "phone": this.form.value.contact,
        "DOB": this.form.value.Dob,
        "gender": this.form.value.gender,
        "school": this.form.value.school,
        "password": this.form.value.password,
        "zip": this.form.value.zip,
        "state": this.form.value.state,
        "country": this.form.value.country,
      }
      this.isLoading = true
      this.auth.signUp(signUpPayload).subscribe((res: any) => {
        if (res.statusCode == 200) {
          // If the request is successful, display a success message and reset the form
          this.err2 = " Creating your account...";
          this.isOpen2 = true
          setTimeout(() => {
            this.err2 = "Account created successfully...";
            this.isOpen2 = true
            this.isLoading = false
            setTimeout(() => {

              this.isOpen2 = false
              this.router.navigate(['/'])
            }, 3000)
          }, 3000)

        } else {
          // If there is an error with the request, display an error message
          this.errorStatusCode(res)
        }
      })

    } else {
      // If the form is invalid, trigger validation messages for all fields
      this.validateAllFormFields(this.form);
    }

  }


  /**
   * Handles an error response with a specific status code.
   * This function is called when an HTTP request returns an error response with a specific status code.
   * @param {any} res - The error response object.
   * @returns {void}
   */
  errorStatusCode(res: any) {
    if (res.statusCode == 408) {
      this.isLoading2 = false
      this.err = res.data;
      this.isOpen = true
      setTimeout(() => {
        this.isOpen = false
      }, 6000)
    } else if (res.statusCode == 403 || res.statusCode == 404) {
      this.isLoading2 = false

      this.err = res.body;
      this.isOpen = true
      setTimeout(() => {
        this.isOpen = false
      }, 6000)
    }
    else {
      this.isLoading2 = false
    }
  }


  // country field dropdown
  countryNames() {
    this.countryName = [{ 'name': 'Afghanistan', 'code': 'AF', 'German': 'Afghanistan' },
    { 'name': 'Åland Islands', 'code': 'AX', 'German': 'Ålandinseln' },
    { 'name': 'Albania', 'code': 'AL', 'German': 'Albanien' },
    { 'name': 'Algeria', 'code': 'DZ', 'German': 'Algerien' },
    { 'name': 'American Samoa', 'code': 'AS', 'German': 'Amerikanischen Samoa-Inseln' },
    { 'name': 'Andorra', 'code': 'AD', 'German': 'Andorra' },
    { 'name': 'Angola', 'code': 'AO', 'German': 'Angola' },
    { 'name': 'Anguilla', 'code': 'AI', 'German': 'Angilla' },
    { 'name': 'Antarctica', 'code': 'AQ', 'German': 'Antarktis' },
    { 'name': 'Antigua and Barbuda', 'code': 'AG', 'German': 'Antigua und Barbuda' },
    { 'name': 'Argentina', 'code': 'AR', 'German': 'Argentinien' },
    { 'name': 'Armenia', 'code': 'AM', 'German': 'Armenien' },
    { 'name': 'Aruba', 'code': 'AW', 'German': 'Aruba' },
    { 'name': 'Australia', 'code': 'AU', 'German': 'Australien' },
    { 'name': 'Austria', 'code': 'AT', 'German': 'Österreich' },
    { 'name': 'Azerbaijan', 'code': 'AZ', 'German': 'Aserbaidschan' },
    { 'name': 'Bahamas', 'code': 'BS', 'German': 'Bahamas' },
    { 'name': 'Bahrain', 'code': 'BH', 'German': 'Bahrain' },
    { 'name': 'Bangladesh', 'code': 'BD', 'German': 'Bangladesch' },
    { 'name': 'Barbados', 'code': 'BB', 'German': 'Barbados' },
    { 'name': 'Belarus', 'code': 'BY', 'German': 'Weißrussland' },
    { 'name': 'Belgium', 'code': 'BE', 'German': 'Belgien' },
    { 'name': 'Belize', 'code': 'BZ', 'German': 'Belize' },
    { 'name': 'Benin', 'code': 'BJ', 'German': 'Benin' },
    { 'name': 'Bermuda', 'code': 'BM', 'German': 'Bermudas' },
    { 'name': 'Bhutan', 'code': 'BT', 'German': 'Bhutan' },
    { 'name': 'Bolivia', 'code': 'BO', 'German': 'Bolivien' },
    { 'name': 'Bosnia and Herzegovina', 'code': 'BA', 'German': 'Bosnien und Herzegowina' },
    { 'name': 'Botswana', 'code': 'BW', 'German': 'Botswana' },
    { 'name': 'Bouvet Island', 'code': 'BV', 'German': 'Bouvetinsel' },
    { 'name': 'Brazil', 'code': 'BR', 'German': 'Brasilien' },
    { 'name': 'British Indian Ocean Territory', 'code': 'IO', 'German': 'Britisches Territorium des Indischen Ozeans' },
    { 'name': 'Brunei Darussalam', 'code': 'BN', 'German': 'Brunei Darussalam' },
    { 'name': 'Bulgaria', 'code': 'BG', 'German': 'Bulgarien' },
    { 'name': 'Burkina Faso', 'code': 'BF', 'German': 'Burkina Faso' },
    { 'name': 'Burundi', 'code': 'BI', 'German': 'Burundi' },
    { 'name': 'Cambodia', 'code': 'KH', 'German': 'Kambodscha' },
    { 'name': 'Cameroon', 'code': 'CM', 'German': 'Kamerun' },
    { 'name': 'Canada', 'code': 'CA', 'German': 'Kanada' },
    { 'name': 'Cape Verde', 'code': 'CV', 'German': 'Kap Verde' },
    { 'name': 'Cayman Islands', 'code': 'KY', 'German': 'Cayman Inseln' },
    { 'name': 'Central African Republic', 'code': 'CF', 'German': 'Zentralafrikanische Republik' },
    { 'name': 'Chad', 'code': 'TD', 'German': 'Tschad' },
    { 'name': 'Chile', 'code': 'CL', 'German': 'Chile' },
    { 'name': 'China', 'code': 'CN', 'German': 'China' },
    { 'name': 'Christmas Island', 'code': 'CX', 'German': 'Weihnachtsinsel' },
    { 'name': 'Cocos (Keeling) Islands', 'code': 'CC', 'German': 'Kokos-(Keeling-)Inseln' },
    { 'name': 'Colombia', 'code': 'CO', 'German': 'Kolumbien' },
    { 'name': 'Comoros', 'code': 'KM', 'German': 'Komoren' },
    { 'name': 'Congo', 'code': 'CG', 'German': 'Kongo' },
    { 'name': 'Congo, The Democratic Republic of the Congo', 'code': 'CD', 'German': 'Kongo, die Demokratische Republik der' },
    { 'name': 'Cook Islands', 'code': 'CK', 'German': 'Cookinseln' },
    { 'name': 'Costa Rica', 'code': 'CR', 'German': 'Costa Rica' },
    { 'name': "Cote D'Ivoire", 'code': 'CI', 'German': 'Elfenbeinküste' },
    { 'name': 'Croatia', 'code': 'HR', 'German': 'Kroatien' },
    { 'name': 'Cuba', 'code': 'CU', 'German': 'Kuba' },
    { 'name': 'Cyprus', 'code': 'CY', 'German': 'Zypern' },
    { 'name': 'Czech Republic', 'code': 'CZ', 'German': 'Tschechien' },
    { 'name': 'Denmark', 'code': 'DK', 'German': 'Dänemark' },
    { 'name': 'Djibouti', 'code': 'DJ', 'German': 'Dschibuti' },
    { 'name': 'Dominica', 'code': 'DM', 'German': 'Dominika' },
    { 'name': 'Dominican Republic', 'code': 'DO', 'German': 'Dominikanische Republik' },
    { 'name': 'Ecuador', 'code': 'EC', 'German': 'Ecuador' },
    { 'name': 'Egypt', 'code': 'EG', 'German': 'Ägypten' },
    { 'name': 'El Salvador', 'code': 'SV', 'German': 'El Salvador' },
    { 'name': 'Equatorial Guinea', 'code': 'GQ', 'German': 'Äquatorialguinea' },
    { 'name': 'Eritrea', 'code': 'ER', 'German': 'Eritrea' },
    { 'name': 'Estonia', 'code': 'EE', 'German': 'Estland' },
    { 'name': 'Ethiopia', 'code': 'ET', 'German': 'Äthiopien' },
    { 'name': 'Falkland Islands (Malvinas)', 'code': 'FK', 'German': 'Falklandinseln (Malvinas)' },
    { 'name': 'Faroe Islands', 'code': 'FO', 'German': 'Färöer Inseln' },
    { 'name': 'Fiji', 'code': 'FJ', 'German': 'Fidschi' },
    { 'name': 'Finland', 'code': 'FI', 'German': 'Finnland' },
    { 'name': 'France', 'code': 'FR', 'German': 'Frankreich' },
    { 'name': 'French Guiana', 'code': 'GF', 'German': 'Französisch-Guayana' },
    { 'name': 'French Polynesia', 'code': 'PF', 'German': 'Französisch Polynesien' },
    { 'name': 'French Southern Territories', 'code': 'TF', 'German': 'Südfranzösische Territorien' },
    { 'name': 'Gabon', 'code': 'GA', 'German': 'Gabun' },
    { 'name': 'Gambia', 'code': 'GM', 'German': 'Gambia' },
    { 'name': 'Georgia', 'code': 'GE', 'German': 'Georgia' },
    { 'name': 'Germany', 'code': 'DE', 'German': 'Deutschland' },
    { 'name': 'Ghana', 'code': 'GH', 'German': 'Ghana' },
    { 'name': 'Gibraltar', 'code': 'GI', 'German': 'Gibraltar' },
    { 'name': 'Greece', 'code': 'GR', 'German': 'Griechenland' },
    { 'name': 'Greenland', 'code': 'GL', 'German': 'Grönland' },
    { 'name': 'Grenada', 'code': 'GD', 'German': 'Grenada' },
    { 'name': 'Guadeloupe', 'code': 'GP', 'German': 'Guadeloupe' },
    { 'name': 'Guam', 'code': 'GU', 'German': 'Guam' },
    { 'name': 'Guatemala', 'code': 'GT', 'German': 'Guatemala' },
    { 'name': 'Guernsey', 'code': 'GG', 'German': 'Guernsey' },
    { 'name': 'Guinea', 'code': 'GN', 'German': 'Guinea' },
    { 'name': 'Guinea-Bissau', 'code': 'GW', 'German': 'Guinea-Bissau' },
    { 'name': 'Guyana', 'code': 'GY', 'German': 'Guyana' },
    { 'name': 'Haiti', 'code': 'HT', 'German': 'Haiti' },
    { 'name': 'Heard Island and Mcdonald Islands', 'code': 'HM', 'German': 'Heard-Insel und McDonald-Inseln' },
    { 'name': 'Holy See (Vatican City State)', 'code': 'VA', 'German': 'Heiliger Stuhl (Vatikanstadt)' },
    { 'name': 'Honduras', 'code': 'HN', 'German': 'Honduras' },
    { 'name': 'Hong Kong', 'code': 'HK', 'German': 'Hongkong' },
    { 'name': 'Hungary', 'code': 'HU', 'German': 'Ungarn' },
    { 'name': 'Iceland', 'code': 'IS', 'German': 'Island' },
    { 'name': 'India', 'code': 'IN', 'German': 'Indien' },
    { 'name': 'Indonesia', 'code': 'ID', 'German': 'Indonesien' },
    { 'name': 'Iran', 'code': 'IR', 'German': 'Iran, Islamische Republik' },
    { 'name': 'Iraq', 'code': 'IQ', 'German': 'Irak' },
    { 'name': 'Ireland', 'code': 'IE', 'German': 'Irland' },
    { 'name': 'Isle of Man', 'code': 'IM', 'German': 'Isle of Man' },
    { 'name': 'Israel', 'code': 'IL', 'German': 'Israel' },
    { 'name': 'Italy', 'code': 'IT', 'German': 'Italien' },
    { 'name': 'Jamaica', 'code': 'JM', 'German': 'Jamaika' },
    { 'name': 'Japan', 'code': 'JP', 'German': 'Japan' },
    { 'name': 'Jersey', 'code': 'JE', 'German': 'Jersey' },
    { 'name': 'Jordan', 'code': 'JO', 'German': 'Jordanien' },
    { 'name': 'Kazakhstan', 'code': 'KZ', 'German': 'Kasachstan' },
    { 'name': 'Kenya', 'code': 'KE', 'German': 'Kenia' },
    { 'name': 'Kiribati', 'code': 'KI', 'German': 'Kiribati' },
    { 'name': "Korea, Democratic People'S Republic of", 'code': 'KP', 'German': 'Korea, Demokratische Volksrepublik' },
    { 'name': 'Korea, Republic of', 'code': 'KR', 'German': 'Korea, Republik von' },
    { 'name': 'Kuwait', 'code': 'KW', 'German': 'Kuwait' },
    { 'name': 'Kyrgyzstan', 'code': 'KG', 'German': 'Kirgistan' },
    { 'name': "Lao People'S Democratic Republic", 'code': 'LA', 'German': 'Demokratische Volksrepublik Laos' },
    { 'name': 'Latvia', 'code': 'LV', 'German': 'Lettland' },
    { 'name': 'Lebanon', 'code': 'LB', 'German': 'Libanon' },
    { 'name': 'Lesotho', 'code': 'LS', 'German': 'Lesotho' },
    { 'name': 'Liberia', 'code': 'LR', 'German': 'Liberia' },
    { 'name': 'Libyan Arab Jamahiriya', 'code': 'LY', 'German': 'Libysche Arabische Jamahiriya' },
    { 'name': 'Liechtenstein', 'code': 'LI', 'German': 'Liechtenstein' },
    { 'name': 'Lithuania', 'code': 'LT', 'German': 'Litauen' },
    { 'name': 'Luxembourg', 'code': 'LU', 'German': 'Luxemburg' },
    { 'name': 'Macao', 'code': 'MO', 'German': 'Macao' },
    { 'name': 'Macedonia, The Former Yugoslav Republic of North Macedonia', 'code': 'MK', 'German': 'Mazedonien, ehemalige jugoslawische Republik' },
    { 'name': 'Madagascar', 'code': 'MG', 'German': 'Madagaskar' },
    { 'name': 'Malawi', 'code': 'MW', 'German': 'Malawi' },
    { 'name': 'Malaysia', 'code': 'MY', 'German': 'Malaysia' },
    { 'name': 'Maldives', 'code': 'MV', 'German': 'Malediven' },
    { 'name': 'Mali', 'code': 'ML', 'German': 'Mali' },
    { 'name': 'Malta', 'code': 'MT', 'German': 'Malta' },
    { 'name': 'Marshall Islands', 'code': 'MH', 'German': 'Marshallinseln' },
    { 'name': 'Martinique', 'code': 'MQ', 'German': 'Martinique' },
    { 'name': 'Mauritania', 'code': 'MR', 'German': 'Mauretanien' },
    { 'name': 'Mauritius', 'code': 'MU', 'German': 'Mauritius' },
    { 'name': 'Mayotte', 'code': 'YT', 'German': 'Mayotte' },
    { 'name': 'Mexico', 'code': 'MX', 'German': 'Mexiko' },
    { 'name': 'Micronesia', 'code': 'FM', 'German': 'Mikronesien, Föderierte Staaten von' },
    { 'name': 'Moldova', 'code': 'MD', 'German': 'Moldawien, Republik' },
    { 'name': 'Monaco', 'code': 'MC', 'German': 'Monaco' },
    { 'name': 'Mongolia', 'code': 'MN', 'German': 'Mongolei' },
    { 'name': 'Montserrat', 'code': 'MS', 'German': 'Montserrat' },
    { 'name': 'Morocco', 'code': 'MA', 'German': 'Marokko' },
    { 'name': 'Mozambique', 'code': 'MZ', 'German': 'Mosambik' },
    { 'name': 'Myanmar', 'code': 'MM', 'German': 'Myanmar' },
    { 'name': 'Namibia', 'code': 'NA', 'German': 'Namibia' },
    { 'name': 'Nauru', 'code': 'NR', 'German': 'Nauru' },
    { 'name': 'Nepal', 'code': 'NP', 'German': 'Nepal' },
    { 'name': 'Netherlands', 'code': 'NL', 'German': 'Niederlande' },
    { 'name': 'Netherlands Antilles', 'code': 'AN', 'German': 'Niederländische Antillen' },
    { 'name': 'New Caledonia', 'code': 'NC', 'German': 'Neu-Kaledonien' },
    { 'name': 'New Zealand', 'code': 'NZ', 'German': 'Neuseeland' },
    { 'name': 'Nicaragua', 'code': 'NI', 'German': 'Nicaragua' },
    { 'name': 'Niger', 'code': 'NE', 'German': 'Niger' },
    { 'name': 'Nigeria', 'code': 'NG', 'German': 'Nigeria' },
    { 'name': 'Niue', 'code': 'NU', 'German': 'Niue' },
    { 'name': 'Norfolk Island', 'code': 'NF', 'German': 'Norfolkinsel' },
    { 'name': 'Northern Mariana Islands', 'code': 'MP', 'German': 'Nördliche Marianneninseln' },
    { 'name': 'Norway', 'code': 'NO', 'German': 'Norwegen' },
    { 'name': 'Oman', 'code': 'OM', 'German': 'Oman' },
    { 'name': 'Pakistan', 'code': 'PK', 'German': 'Pakistan' },
    { 'name': 'Palau', 'code': 'PW', 'German': 'Palau' },
    { 'name': 'Palestinian Territory', 'code': 'PS', 'German': 'Besetzte palästinensische Gebiete' },
    { 'name': 'Panama', 'code': 'PA', 'German': 'Panama' },
    { 'name': 'Papua New Guinea', 'code': 'PG', 'German': 'Papua Neu-Guinea' },
    { 'name': 'Paraguay', 'code': 'PY', 'German': 'Paraguay' },
    { 'name': 'Peru', 'code': 'PE', 'German': 'Peru' },
    { 'name': 'Philippines', 'code': 'PH', 'German': 'Philippinen' },
    { 'name': 'Pitcairn', 'code': 'PN', 'German': 'Pitcairn' },
    { 'name': 'Poland', 'code': 'PL', 'German': 'Polen' },
    { 'name': 'Portugal', 'code': 'PT', 'German': 'Portugal' },
    { 'name': 'Puerto Rico', 'code': 'PR', 'German': 'Puerto-Rico' },
    { 'name': 'Qatar', 'code': 'QA', 'German': 'Katar' },
    { 'name': 'Reunion', 'code': 'RE', 'German': 'Wiedervereinigung' },
    { 'name': 'Romania', 'code': 'RO', 'German': 'Rumänien' },
    { 'name': 'Russian Federation', 'code': 'RU', 'German': 'Russische Föderation' },
    { 'name': 'RWANDA', 'code': 'RW', 'German': 'RUANDA' },
    { 'name': 'Saint Helena', 'code': 'SH', 'German': 'Heilige Helena' },
    { 'name': 'Saint Kitts and Nevis', 'code': 'KN', 'German': 'St. Kitts und Nevis' },
    { 'name': 'Saint Lucia', 'code': 'LC', 'German': 'Heilige Lucia' },
    { 'name': 'Saint Pierre and Miquelon', 'code': 'PM', 'German': 'St. Pierre und Miquelon' },
    { 'name': 'Saint Vincent and the Grenadines', 'code': 'VC', 'German': 'St. Vincent und die Grenadinen' },
    { 'name': 'Samoa', 'code': 'WS', 'German': 'Samoa' },
    { 'name': 'San Marino', 'code': 'SM', 'German': 'San Marino' },
    { 'name': 'Sao Tome and Principe', 'code': 'ST', 'German': 'Sao Tome und Principe' },
    { 'name': 'Saudi Arabia', 'code': 'SA', 'German': 'Saudi-Arabien' },
    { 'name': 'Senegal', 'code': 'SN', 'German': 'Senegal' },
    { 'name': 'Serbia and Montenegro', 'code': 'CS', 'German': 'Serbien und Montenegro' },
    { 'name': 'Seychelles', 'code': 'SC', 'German': 'Seychellen' },
    { 'name': 'Sierra Leone', 'code': 'SL', 'German': 'Sierra Leone' },
    { 'name': 'Singapore', 'code': 'SG', 'German': 'Singapur' },
    { 'name': 'Slovakia', 'code': 'SK', 'German': 'Slowakei' },
    { 'name': 'Slovenia', 'code': 'SI', 'German': 'Slowenien' },
    { 'name': 'Solomon Islands', 'code': 'SB', 'German': 'Salomon-Inseln' },
    { 'name': 'Somalia', 'code': 'SO', 'German': 'Somalia' },
    { 'name': 'South Africa', 'code': 'ZA', 'German': 'Südafrika' },
    { 'name': 'South Georgia and the South Sandwich Islands', 'code': 'GS', 'German': 'Süd-Georgien und die südlichen Sandwich-Inseln' },
    { 'name': 'Spain', 'code': 'ES', 'German': 'Spanien' },
    { 'name': 'Sri Lanka', 'code': 'LK', 'German': 'Sri Lanka' },
    { 'name': 'Sudan', 'code': 'SD', 'German': 'Sudan' },
    { 'name': 'Suriname', 'code': 'SR', 'German': 'Suriname' },
    { 'name': 'Svalbard and Jan Mayen', 'code': 'SJ', 'German': 'Spitzbergen und Jan Mayen' },
    { 'name': 'Swaziland', 'code': 'SZ', 'German': 'Swasiland' },
    { 'name': 'Sweden', 'code': 'SE', 'German': 'Schweden' },
    { 'name': 'Switzerland', 'code': 'CH', 'German': 'Schweiz' },
    { 'name': 'Syrian Arab Republic', 'code': 'SY', 'German': 'Syrische Arabische Republik' },
    { 'name': 'Taiwan, Province of China', 'code': 'TW', 'German': 'Taiwan, Provinz Chinas' },
    { 'name': 'Tajikistan', 'code': 'TJ', 'German': 'Tadschikistan' },
    { 'name': 'Tanzania', 'code': 'TZ', 'German': 'Tansania, Vereinigte Republik' },
    { 'name': 'Thailand', 'code': 'TH', 'German': 'Thailand' },
    { 'name': 'Timor-Leste', 'code': 'TL', 'German': 'Timor-Leste' },
    { 'name': 'Togo', 'code': 'TG', 'German': 'Gehen' },
    { 'name': 'Tokelau', 'code': 'TK', 'German': 'Tokelau' },
    { 'name': 'Tonga', 'code': 'TO', 'German': 'Tonga' },
    { 'name': 'Trinidad and Tobago', 'code': 'TT', 'German': 'Trinidad und Tobago' },
    { 'name': 'Tunisia', 'code': 'TN', 'German': 'Tunesien' },
    { 'name': 'Turkey', 'code': 'TR', 'German': 'Truthahn' },
    { 'name': 'Turkmenistan', 'code': 'TM', 'German': 'Turkmenistan' },
    { 'name': 'Turks and Caicos Islands', 'code': 'TC', 'German': 'Turks- und Caicosinseln' },
    { 'name': 'Tuvalu', 'code': 'TV', 'German': 'Tuvalu' },
    { 'name': 'Uganda', 'code': 'UG', 'German': 'Uganda' },
    { 'name': 'Ukraine', 'code': 'UA', 'German': 'Ukraine' },
    { 'name': 'United Arab Emirates', 'code': 'AE', 'German': 'Vereinigte Arabische Emirate' },
    { 'name': 'United Kingdom', 'code': 'GB', 'German': 'Vereinigtes Königreich' },
    { 'name': 'United States', 'code': 'US', 'German': 'Vereinigte Staaten' },
    { 'name': 'United States Minor Outlying Islands', 'code': 'UM', 'German': 'Kleinere abgelegene Inseln der Vereinigten Staaten' },
    { 'name': 'Uruguay', 'code': 'UY', 'German': 'Uruguay' },
    { 'name': 'Uzbekistan', 'code': 'UZ', 'German': 'Usbekistan' },
    { 'name': 'Vanuatu', 'code': 'VU', 'German': 'Vanuatu' },
    { 'name': 'Venezuela', 'code': 'VE', 'German': 'Venezuela' },
    { 'name': 'Viet Nam', 'code': 'VN', 'German': 'Vietnam' },
    { 'name': 'Virgin Islands, British', 'code': 'VG', 'German': 'Virgin Inseln, Britisch' },
    { 'name': 'Virgin Islands, U.S.', 'code': 'VI', 'German': 'Jungferninseln, USA' },
    { 'name': 'Wallis and Futuna', 'code': 'WF', 'German': 'Wallis und Futuna' },
    { 'name': 'Western Sahara', 'code': 'EH', 'German': 'Westsahara' },
    { 'name': 'Yemen', 'code': 'YE', 'German': 'Jemen' },
    { 'name': 'Zambia', 'code': 'ZM', 'German': 'Sambia' },
    { 'name': 'Zimbabwe', 'code': 'ZW', 'German': 'Zimbabwe' }]

  }

}

<div class="back">
  <br>
  <div class="col-lg-4 mx-auto mt-5">
    <div class="card  rounded">
      <div class="auth-form-light text-left p-5">
        <div class="brand-logo d-flex justify-content-center">
          <img src="../../assets/images/edyou-r.png" style="width: 180px;">
        </div>
        <h4 class="mt-2 d-flex justify-content-center">Recover Password</h4>
        <h6 class="font-weight-light d-flex justify-content-center mt-3">We will send you a link to reset password.</h6>
        <form class="pt-3" [formGroup]="forgetForm">

          <div class="input-group-login sombreado-input">
            <div class="input-group-prepend-login">
              <span class="input-group-text fondo-icon">
                <i class="fas fa-envelope"></i>
              </span>

            </div>

            <input type="text" class="border-0-login form-control-login input-border-none" formControlName="email"
              name="email" placeholder="Email" aria-label="Email">


          </div>
          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="label__error border__error">
            <div *ngIf="email.errors?.['required']">
              Email is required
            </div>
            <div *ngIf="email.errors?.['pattern']">
              Invalid Email

            </div>
          </div>

          <div class="mt-3">
            <button class="btn fontss text-white btn-block mt-1 btn-social" style="height:45px ;"
              (click)="submit()"><img class="" style="width : 24px; color:white;" *ngIf="isLoading"
                src="../../assets/images/Rolling-1s-200px (2).gif">
              <span class="mt-2" *ngIf="!isLoading">Submit</span></button>
          </div>
          <div class="mt-3 d-flex justify-content-center" style="cursor: pointer;">
            <a class="" style="height:45px ;" (click)="backFunction()"><img
                src="../../assets/images/icons8-back-arrow-48.png"></a>
          </div>

        </form>

        <div style="color: red;" class="error text-center" *ngIf="isOpen">
          <span class="label__error border__error"> {{err}}</span>
        </div>
      </div>
    </div>

  </div>

</div>
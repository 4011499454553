import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPageComponent } from './forget-page/forget-page.component';
import { LoginComponent } from './login/login.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermsandConditionComponent } from './termsand-condition/termsand-condition.component';
import { ZoomLoginComponent } from './zoomMeeting/zoom-login/zoom-login.component';
import { GuardGuard } from './AuthGuard/guard.guard';
import { HelppageComponent } from './helppage/helppage.component';
import { SSOSAMComponent } from './sso-sam/sso-sam.component';
import { MicPermissionPageComponent } from './user-home/component/mic-permission-page/mic-permission-page.component';
import { TestpageComponent } from './testpage/testpage.component';

const routes: Routes = [
  {path: '', component:LoginComponent},
  {path: 'terms', component:TermsandConditionComponent},
  {path: 'ssoSamlCallback', component:SSOSAMComponent},
  {path: 'zoom', component:ZoomLoginComponent},
  {path: 'meetingLink', loadChildren: () => import('./zoomMeeting/zoom-meeting-page/zoom-meeting-page.module').then(m => m.ZoomMeetingPageModule) },
  {path: 'help', component:HelppageComponent},
  {path: 'signUp', component:SignUpComponent},
  {path: 'testpage', component:TestpageComponent},
  {path: 'forgetPassword', component:ForgetPageComponent},
  {path: 'setPassword', component:SetPasswordComponent},
  { path: 'user', loadChildren: () => import('./user-home/user-home.module').then(m => m.UserHomeModule) ,  canActivate: [GuardGuard]},
  { path: 'bookDemo', loadChildren: () => import('./book-demo-page/book-demo-page.module').then(m => m.BookDemoPageModule) },
  {path: 'microphonePermission', component:MicPermissionPageComponent},
 




 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

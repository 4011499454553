import { Injectable } from '@angular/core';
import { API } from '../service/restapi';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.baseUrl;
  authToken: any;
  user: any;
  public profilepicUrl = new BehaviorSubject<string>('')
  constructor(private http: HttpClient) { }


  getIpAdress() {
    return this.http.get('https://geolocation-db.com/json/')
    // return this.http.get("http://api.ipify.org/?format=json");  
  }


  zoomMeetingConversation(data: any) {
    return this.http.post<any>(this.baseUrl + API.conversationSaveData, data);
  }


  signIn(data: any) {
    return this.http.post<any>(this.baseUrl + API.login, data);
  }

  signUp(data: any) {
    return this.http.post<any>(this.baseUrl + API.signUp, data);
  }


  forgetPassword(forgetPass: any) {
    return this.http.post<any>(this.baseUrl + API.forget, forgetPass);
  }

  setPasswordTimer(timer: any) {
    return this.http.post<any>(this.baseUrl + API.setPasswordTimer, timer);
  }

  setPassword(newPass: any) {
    return this.http.post<any>(this.baseUrl + API.setPassword, newPass);
  }

  uneeqAvatar(payload: any) {
    return this.http.post<any>(this.baseUrl + API.uneeqAvatarToken, payload);

  }


  getProfile(getprofileData: any) {
    return this.http.post<any>(this.baseUrl + API.getProfile, getprofileData);
  }


  updateProfile(updateprofileData: any) {
    return this.http.post<any>(this.baseUrl + API.updateProfile, updateprofileData);
  }

  changePassword_Profile(newPass: any) {
    return this.http.post<any>(this.baseUrl + API.changePassword, newPass);
  }

  logOut(logout: any) {
    return this.http.post<any>(this.baseUrl + API.logout, logout);
  }

  // book slot API 
  SlotLogin(slotData: any) {
    return this.http.post<any>(this.baseUrl + API.slotLogin, slotData);
  }

  slotPage(slotDetails: any) {
    return this.http.post<any>(this.baseUrl + API.slotPage, slotDetails);
  }

  bookSlot(bookSlotData: any) {
    return this.http.post<any>(this.baseUrl + API.bookSlot, bookSlotData);
  }
  // ------


  ssoVerifyLogin(sso:any){
    return this.http.post<any>(this.baseUrl + API.ssoRedirectVerify, sso);
  }

  verifySlotBooking(slotBook:any){
    return this.http.post<any>(this.baseUrl + API.verifySlotBokking, slotBook);
  }



  storedLocalStorageData() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("Avatar");
  }
  receivePic(data:any){
    // this.subject.next(data)
    this.profilepicUrl.next(data)
  }
  // sendpic(){
  //   return this.subject.asObservable()
  // }

  deletphoto(data:any){
    return this.http.post(this.baseUrl+ API.deleteprofilepicture,data)
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-forget-page',
  templateUrl: './forget-page.component.html',
  styleUrls: ['./forget-page.component.css']
})
export class ForgetPageComponent implements OnInit {

  err: string = '';
  isOpen: boolean = false;
  isLoading: boolean = false;

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit(): void {
  }

  //form
  forgetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),

  })

  get email() {
    return this.forgetForm.controls['email'];
  }


  validateAllFormFields(formGroup: FormGroup) {
    // Iterate over each field in the form group
   Object.keys(formGroup.controls).forEach(field => {
     const control = formGroup.get(field);
     // Check if the control is an individual form control
     if (control instanceof FormControl) {
       control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
     } else if (control instanceof FormGroup) {
       this.validateAllFormFields(control);
     }
   });
 }

 
 /**
 * Handles the submission of a forget password request.
 * This function initiates the process of resetting a user's password.
 * @returns {void}
 */
  submit() {
    if (this.forgetForm.valid) {
      // If the form is valid, initiate the password reset request
      this.isLoading = true
      let Payload = {
        "email": this.forgetForm.value.email,
        "role": 'User'
      }
      this.auth.forgetPassword(Payload).subscribe(
        (res) => {
          if (res.statusCode == 200) {
            // If the request is successful, display a success message and reset the form
            this.isLoading = false
            this.forgetForm.reset()
            this.err = res.message;
            this.isOpen = true
            setTimeout(() => {
              this.isOpen = false
            }, 4000)
          } else if (res.statusCode == 406 || res.statusCode == 401) {
            // If there is an error with the request, display an error message
            this.err = res.message;
            this.isOpen = true
            setTimeout(() => {
              this.isOpen = false
            }, 5000)
            this.isLoading = false;
          } else {
            // Handle any other unexpected responses
            this.isLoading = false
          }
        })
    } else {
      // If the form is invalid, trigger validation messages for all fields
      this.validateAllFormFields(this.forgetForm);

    }
  }


  // Navigate to the login page
  backFunction() {
    this.router.navigate(['/'])
  }

}

<div class="user-band">
  <span class="user-details">
    <h3 class="ml-3 " style="font-size: 22px; font-family: 'DM Sans Medium', sans-serif;">Help Guide</h3>
  </span>
</div>

<div class="col-md-12">
  <div class="tab">
    <button class="tablinks HeadingFontMobile active" id="base-tab33" (click)="tab3()">Instructions</button>
    <button class="tablinks  HeadingFontMobile " id="base-tab11" (click)="tab1()">Commands</button>
    <button class="tablinks HeadingFontMobile" id="base-tab22" (click)="tab2()">FAQ's</button>

  </div>



  <div id="tab11" class="tabcontent">
    <div class="div mt-3" style="display:flex ; justify-content:center">
      <br>
      <h1>General</h1>
    </div>

    <div style="display: flex; justify-content: center;">

      <div class=" col-md-8 ">
        <div class="gerneal">
          <h4>{{AvatarName}}</h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To turn on {{AvatarName}}'s closed captions, ask {{AvatarName}}:</h5>
              <ol type="A" id="accod1" style="display: block;">
                <li> - Turn on your subtitles.</li>
                <li> - Show your subtitles. </li>

              </ol>
            </li>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To turn off {{AvatarName}}'s closed captions, ask {{AvatarName}}:</h5>
              <ol type="A">
                <li>- Turn off your subtitles.</li>

                <li>- Hide your subtitles.</li>
              </ol>
            </li>

          </ul>
          <br>
          <h4>User</h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To turn on the user’s closed captions, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Turn on user subtitles.</li>
                <!-- <li> - Display user subtitles. </li>
            <li> - Enable my subtitles.</li> -->
                <li>- Show user subtitles.</li>
              </ol>
            </li>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To turn off the user’s closed captions, ask {{AvatarName}}:</h5>
              <ol type="A">
                <li>- Turn off user subtitles.</li>

                <li>- Hide user subtitles.</li>
              </ol>
            </li>

          </ul>
          <br>
          <h4> Full Screen </h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To show full screen, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Display fullscreen. </li>

                <li> - Maximize the screen.</li>

              </ol>
            </li>


          </ul>
          <br>

          <h4> Small Screen </h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To show small screen, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Make yourself small screen. </li>

                <li> - Minimize the screen.</li>

              </ol>
            </li>


          </ul>
          <br>

          <h4> Dashboard</h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To go to the dashboard, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Open the dashboard.</li>

                <li>- Go to the dashboard. </li>

              </ol>
            </li>


          </ul>
          <br>

          <h4> Learning Module</h4>
          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h5>To go to the learning module, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Open learning module.</li>

                <li> - Go to the presentation.</li>
              </ol>
            </li>


          </ul>

          <br>

          <h4> Test Prep </h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To go to the Test Prep, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Open the Test Prep.</li>

                <li> - Go to Test Prep.</li>

              </ol>
            </li>


          </ul>
          <br>

          <h4> Logout</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To log out the application, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Logout.</li>

                <li> - Sign off.</li>
              </ol>
            </li>


          </ul>

          <br>

          <h4>Hide {{AvatarName}} </h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i>  -->
              <h5>To hide the {{AvatarName}} box, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Please hide yourself.</li>
                <li>- Kindly hide the AI Being. </li>


              </ol>
            </li>


          </ul>
          <br>

          <h4>Unhide {{AvatarName}}</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To unhide {{AvatarName}} box, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Show yourself.</li>

                <li> - Display yourself. </li>

              </ol>
            </li>


          </ul>

          <br>


          <br>
          <div class="div mt-3" style="display:flex ; justify-content:center">
            <br><br>
            <h1>Learning Module</h1>

          </div>

          <h4>Next Slide </h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To move to the next slide, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li> - Next.</li>

                <li>- Go to the next slide. </li>

              </ol>
            </li>
          </ul>
          <br>

          <h4>Previous Slide </h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To move to the previous slide, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Previous.</li>
                <li>- Go to the previous slide. </li>

              </ol>
            </li>
          </ul>
          <br>


          <h4>Repeat the Slide</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To repeat a particular slide, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Repeat.</li>

                <li>- Please repeat the slide. </li>

              </ol>
            </li>
          </ul>
          <br>
          <h4>Close Learning Module</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To close the presentation, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Close.</li>
                <li>- Exit the module. </li>

              </ol>
            </li>
          </ul>
          <br><br>
          <div class="div mt-3" style="display:flex ; justify-content:center">
            <br><br>
            <h1>Test Prep</h1>

          </div>

          <h4>Display Question</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To display the question, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Show question.</li>
                <li>- Display question. </li>

              </ol>
            </li>
          </ul>

          <br>
          <h4>Display Options</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To display the options, ask {{AvatarName}}: </h5>
              <ol type="A">

                <li>- Show options. </li>

              </ol>
            </li>
          </ul>

          <br>
          <h4>Choose Option</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To provide an answer, ask {{AvatarName}}:</h5>
              <ol type="A">

                <li>- Option A, B, C. </li>

                <li> - Choose A, B, C.</li>
              </ol>
            </li>
          </ul>

          <br>
          <h4>Next Question</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To move to the next question, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Yes.</li>
                <li>- Next question. </li>

              </ol>
            </li>
          </ul>
          <br>

          <h4>Repeat Question</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5>To repeat the question, ask {{AvatarName}}: </h5>
              <ol type="A">
                <li>- Can you please repeat. </li>

                <li>- Repeat the question. </li>
              </ol>
            </li>
          </ul>
          <br>

          <h4>Stop Test Prep</h4>
          <ul>
            <li>
              <input type="checkbox">
              <!-- <i></i> -->
              <h5> To exit the Test Prep, ask {{AvatarName}}:</h5>
              <ol type="A">
                <li> - Stop. </li>

                <li> - Finish the quiz. </li>
              </ol>
            </li>
          </ul>

          <br> <br>
          <br>
          <br> <br>
          <br> <br>

          <!-- <p> <span class="disclaimer" style="color: red;">Disclaimer! - Answers retrieved from sources
              such as Open AI may produce<br>
              inaccurate information about people, places, or facts. </span></p>
          <br>
          <p> <span class="disclaimer" style="color: red;">Warning! - Refreshing the page when in Learning
              Module will reset the slides. </span></p> -->
        </div>
      </div>
    </div>

  </div>


  <div id="tab22" class="tabcontent">

    <div class="faq">
      <div class="div mt-3" style="display:flex ; justify-content:center">
        <br><br>
        <h1>FAQ</h1>

      </div>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q1: What should I do if I encounter a blank screen during a conversation? </h5>
          <p> If you encounter a blank screen during a conversation, please refresh the page. </p>
        </li>
      </ul>
      <br>


      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5>Q2: What should I do if my session expires? </h5>
          <p>You will receive a pop-up message indicating that the session has expired. Click "OK" and re-login again to
            continue. </p>
        </li>
      </ul>


      <br>


      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q3: What should I do if {{AvatarName}} is not speaking? </h5>
          <p>If {{AvatarName}} is not speaking, please refresh or re-login again. </p>
        </li>
      </ul>
      <br>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q4: What should I do if I don't like any answer by {{AvatarName}}? </h5>
          <p>Please click on the feedback icon and submit your feedback. </p>
        </li>
      </ul>
      <br>


      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q5: What should I do if I need to know how to use the application? </h5>
          <p>Click on the info icon on {{AvatarName}} interaction page or go to the help page. </p>
        </li>
      </ul>
      <br>



      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q6: What should I do if I am unable to log in to the application because my account has expired? </h5>
          <p>Please contact your administrator or email at support@edyou.com for assistance. </p>
        </li>
      </ul>
      <br>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q7: What should I do if the presentation doesn't load? </h5>
          <p> Please refresh the page or close the learning module and restart the presentation again. </p>
        </li>
      </ul>
      <br>
      


      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5>Q8: What if I'm not able to see {{AvatarName}} on screen? </h5>
          <p>If you are not able to see {{AvatarName}} on screen just refresh the page once. </p>
        </li>
      </ul>
      <br>


      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q9: What if I get a network issue while having a conversation? </h5>
          <p> If you get a network issue while having a conversation you will get a pop message that you lost
            connection, Click “OK” to refresh the page. </p>
        </li>
      </ul>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q10: Why is the text box not visible in the learning module, and how can I fix it? </h5>
          <p> The text box may not be visible in the learning module due to problems related to the zoom size. Please
            adjust the zoom level by decreasing the size of your browser or you can resize the window of your system.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q11: How can I adjust the speech speed of the AI? </h5>
          <p> To change the AI's speech speed, go to the Interaction screen, navigate to the menu, and modify the speech speed by adjusting the settings.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <input type="checkbox">
          <!-- <i></i> -->
          <h5> Q11: What should I do if different approaches are not showing in the mathematics module when a question is asked? </h5>
          <p>If the approaches are not displaying in the mathematics module for the given question, refresh the page or re-try the question.
          </p>
        </li>
      </ul>

      <br> <br> <br> <br>
      <br> <br> <br>
      <br>
    </div>

  </div>

  <div id="tab33" class="tabcontent" style="display:table">

    <div class="div mt-3" style="display:flex ; justify-content:center">
      <br>
      <h1>Instructions</h1>
    </div>
<br>
    <div style="display: flex; justify-content: center;">
     

      <div class=" col-md-8 ">
        <div class="instruction">
          
          
          <h4>AI Being Interaction Instructions</h4>
          <ul>
            <li>


              <ol type="A" id="accod1" style="display: block;">
                <li>- Use the microphone button once or press the spacebar button once to speak and ask questions to
                  {{AvatarName}}.</li>
                <li> - Use the chat box to interact with {{AvatarName}} by typing your questions to ask. </li>
                <li> - If you would like to provide feedback, click on the feedback button. Enter your feedback and
                  submit.
                </li>
                <li>- To get information regarding the interaction with {{AvatarName}}, you can click on the info
                  button. </li>
                <li>- Use the CC button to turn on/off User and {{AvatarName}} captions or, you can also click on the
                  microphone
                  button and give verbal commands like "Turn on my subtitles" or "Turn off your subtitles." </li>
                <li>- If you need to interrupt {{AvatarName}} while she is speaking, you can click on the "Pause"
                  button. </li>
                <li>- If you want to go to the dashboard page, simply click on the right-hand side minimize icon or you
                  can click on the microphone button and ask {{AvatarName}} to "Open Dashboard”. </li>
                <li>- To enhance your learning experience, On the mathematics module by clicking on the
                  mathematics button. </li>
                <li>- Navigate to the menu and modify the speech speed of the AI by adjusting the speed settings. </li>
              </ol>
            </li>
            <br>
            <li>


              <h5>Note: In AI Being Interaction Page do not use Verbal Commands like:</h5>
              <ol type="A">
                <li>- Next. </li>

                <li>- Previous. </li>
                <li>- Repeat </li>
                <li>- Stop/Close. </li>
              </ol>
            </li>

          </ul>
          <br>
          <br>


          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h4>Learning Module Instructions </h4>
              <ol type="A">
                <li>- Go to the <span class="bold">Learning Module</span>.</li>
                <li>- Choose the module.</li>
                <li>- Click on Start.</li>
                <li>- Click on the Start Learning button.</li>
                <li>- To move to the slide next ask {{AvatarName}} to move to the <span class="bold">Next</span> slide
                  or click
                  on the <span class="bold">forward</span> arrow.
                </li>
                <li>- To move to the previous slide ask {{AvatarName}} to move to the <span class="bold">Previous</span>
                  slide
                  or click on the <span class="bold">backward </span>
                  arrow.</li>
                <li>- If you want {{AvatarName}} to <span class="bold">Repeat</span> a slide you can click on the <span
                    class="bold">play</span> button or ask {{AvatarName}} to repeat.
                </li>
                <li>- To <span class="bold">Close</span> the module click on the cross button or ask {{AvatarName}} to
                  close it.
                </li>
                <li>- If you want to <span class="bold">Download</span> the presentation, click on the download icon.
                </li>
                <li>- If you want to interrupt {{AvatarName}} while speaking, click on the <span
                    class="bold">Pause</span>
                  button.</li>

                <li>- Navigate to the menu and modify the speech speed of the AI by adjusting the speed settings. </li>


              </ol>


            </li>

            <li>


              <h5>Note: In Learning Module do not use Verbal Commands like:</h5>
              <ol type="A">
                <li>- Open Dashboard.</li>
                <li>- Open Test Prep.</li>
                <li>- Show question.</li>
                <li>- Show option</li>
                <li>- Show yourself small screen.</li>
                <li>- Stop. </li>

              </ol>
            </li>

          </ul>

          <br>
          <span class="disclaimer" style="color: red; font-size: 17px;">Note: The mathematics module will not be
            utilized during learning.</span><br>
          <br>
          <span class="disclaimer" style="color: red; font-size: 17px;">Disclaimer! - We utilize our proprietary
            platform and closed-end LLM system with strict safeguards. While we strive to have the highest accuracy, the
            system may, in rare instances, generate incorrect or misleading information. If this occurs, please report
            this to a support staff agent. </span>
          <br><br>
          <span class="disclaimer" style="color: red; font-size: 17px;">Warning! - Refreshing the page will reset the
            slides. </span>

          <br>


          <br>

          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h4>Test Prep Instructions </h4>
              <ol type="A">
                <li>- Go to the <span class="bold">Test Prep.</span></li>
                <li>- Choose the test.</li>
                <li>- Click on Start.</li>
                <li>- Click on the Start the Quiz button.</li>
                <li>- To move to the next question, ask {{AvatarName}} or type <span class="bold">Yes</span>.</li>
                <li>- To repeat the question, ask {{AvatarName}} or type <span class="bold">Repeat</span>.</li>
                <li>- To stop the Quiz, ask {{AvatarName}} or type <span class="bold">Stop</span>.</li>

                <li>- If you want to interrupt the AI Being, click on the <span class="bold">Pause</span> button.</li>
                <li>- To give answers to questions in the Test Prep you can click on the option to choose or ask
                  {{AvatarName}} (e.g.) Option A .</li>


                <li>- Navigate to the menu and modify the speech speed of the AI by adjusting the speed settings. </li>





              </ol>
            </li>

            <li>

              <span class="disclaimer" style="color: red; font-size: 17px;">Note: The mathematics module will not be
                utilized during test prep.
              </span>
              <br><br>
              <span class="disclaimer" style="color: red; font-size: 17px;">Warning ! - Refreshing the page will reset
                the quiz.
              </span>
              <br> <br>

              <h5>Note: In the Test Prep do not use Verbal Commands like:</h5>
              <ol type="A">
                <li>- Open Dashboard.</li>
                <li>- Open Learning Module.</li>
                <li>- Next. </li>
                <li>- Previous </li>
                <li>- Show yourself small screen.</li>
                <li>- Close.</li>

              </ol>
            </li>

          </ul>


          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h4>Mathematics Module Instructions </h4>
              <ol type="A">
                <li>- Go to the <span class="bold">Mathematics module.</span></li>
                <li>- Click on the mathematics button to enable/disable the mathematics mode.</li>
                <li>- Navigate to the menu and modify the speech speed of the AI by adjusting the speed settings.</li>
                <li>- Click on "different approaches in mathematics" for a more comprehensive understanding.</li>
                <li>- Click on the close to close the module.</li>
              </ol>
            </li>

            <li>

              <span class="disclaimer" style="color: red; font-size: 17px;">Note: When using the mathematics module,
                limit your inquiries to mathematics-related questions.<br>
                If you have general questions, please disable the mathematics module by clicking the mathematics button.
              </span>
              <br><br>
              <span class="disclaimer" style="color: red; font-size: 17px;">Warning: Refresh the page if any issues
                arise.
                
              </span>
              <br> <br>
            </li>

          </ul>

          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h4>Profile Instructions </h4>
              <ol type="A">
                <li>- Go to Profile.</li>
                <li>- Navigate to the section where you can upload profile images and edit your information.</li>
                <li>- Choose "Change Password" to update and modify your password.</li>


              </ol>
            </li>

            <li>



          </ul>


          <ul>
            <li>
              <input type="checkbox" checked>
              <!-- <i></i> -->
              <h4>History Instructions </h4>
              <ol type="A">
                <li>- Navigate to the History Page.</li>
                <li>- Select "View" to access and review all your conversations.</li>

              </ol>
            </li>

            <li>


            </li>

          </ul>

          <br> <br> <br>
          <br> <br> <br>
          <br>
          <br>

        </div>
      </div>
    </div>
  </div>
</div>
import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';


@Component({
  selector: 'app-testpage',
  templateUrl: './testpage.component.html',
  styleUrls: ['./testpage.component.css']
})
export class TestpageComponent implements OnInit ,AfterViewInit {




  ngOnInit(): void {

    // mfe.addEventListener("focusin", () =>  mathVirtualKeyboard.show());
    // mfe.addEventListener("focusout", () =>  mathVirtualKeyboard.hide());
  }
  
  ngAfterViewInit() {
  

    
  }





}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { validateConfirmPassword } from '../directives/customValidator.directive';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  id: any;
  hidePassword: boolean = false
  setPasswordForm = new FormGroup({});
  err: string = '';
  isOpen: boolean = true;
  isLoading2: boolean = false;
  hide: boolean = false;
  lang: any | null;
  newEmail: any;
  oldData: any;
  hideConfirmPassword: boolean = false;
  hideSepasswordForm: boolean = false;
  constructor(private actRouter: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private auth: AuthService) { }

  ngOnInit(): void {
    // this.hide = true
    this.actRouter.queryParams.subscribe((queryParams) => {
      var code = queryParams['code'] || null;
      //  console.log(code)
      var old = queryParams['user_id'] || null;
      this.newEmail = queryParams['Newemail'] || null;
      this.id = old.split("___")
      console.log(this.oldData)

    })

    this.setPasswordForm = this.formBuilder.group({
      password: new FormControl('', [ Validators.required,Validators.minLength(8)]),
      confirm_password: new FormControl('', [ Validators.required,Validators.minLength(8)]),
    }, { validator: validateConfirmPassword('password', 'confirm_password') });

    var timer = {
      //  "table": this.code[0],
      "id": this.id[0]
    }
    console.log(timer)
    this.auth.setPasswordTimer(timer).subscribe((res: any) => {
      if (res.statusCode == 200) {

        this.hide = true 

        if(res.body == "set your password"){
          this.hideSepasswordForm = true
          this.isOpen = false
        }

      } else if (res.statusCode == 410) {
        this.hideSepasswordForm = true
        this.hide = false
        this.err = res.body;
       this.isOpen = true
      }
    })

  }


  get password() {
    return this.setPasswordForm.controls['password'];
  }

  get confirm_password() {
    return this.setPasswordForm.controls['confirm_password'];
  }




  // activate  validation on submit
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
 * Handles the submission of a form or user input.
 * This function is responsible for processing and submitting user input, typically from a form.
 * @returns {void}
 */
  submit() {
    if (this.setPasswordForm.valid) {
      let resetPayload = {
        "id": this.id[0],
        "conf_password": this.setPasswordForm.value.confirm_password,
      }
      this.isLoading2 = true
      this.auth.setPassword(resetPayload).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.isOpen = true;
          this.isLoading2 = false
          this.err = res.body;
        } else {
          this.isLoading2 = false
        }
      })
    } else {
      this.validateAllFormFields(this.setPasswordForm);
    }
  }


  // Navigate to login screen
  backFunction() {
    localStorage.getItem("token");
    localStorage.getItem("user");
    this.router.navigate(['/'])
  }


  // hide password field
  hidepassword() {
    this.hidePassword = !this.hidePassword
  }

 // hide confirm  password field
  hideconfirmPassword() {
    this.hideConfirmPassword = !this.hideConfirmPassword
  }
}

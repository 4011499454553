<div class="back">
  <br>
  <div class="col-lg-4 mx-auto mt-5">
    <div class="card  rounded">
      <div class="auth-form-light text-left p-5">
        <div class="brand-logo d-flex justify-content-center">
          <img src="../../assets/images/edyou-r.png" style="width: 180px;">
        </div>

        <h4 class="mt-2 d-flex justify-content-center">Set Password</h4>
        <ng-container *ngIf="!hideSepasswordForm">
          <div class="div mt-5 mb-5" style="display: flex; justify-content: center;">
            <img class="" style="width : 24px; color:rgb(211, 20, 20);" src="../../assets/Rolling.gif">&nbsp; Loading..
          </div>
        </ng-container>

        <ng-container *ngIf="hideSepasswordForm">

          <div class="card-subtitle  text-muted text-center pt-2" style="font-size: 20px;" *ngIf="isOpen">
            <br>
            <span class="fontss"> {{err}}</span>
          </div>

          <form class="pt-3" [formGroup]="setPasswordForm">

            <div class="di" *ngIf="!isOpen">


              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-lock"></i>
                  </span>

                </div>

                <input [type]="hidePassword?'text':'password'"
                  class="border-0-login form-control-login input-border-none" formControlName="password" name="password"
                  placeholder="Password">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon" (click)="hidepassword()">
                    <i [ngClass]="hidePassword?'fas fa-eye':'fas fa-eye-slash'"></i>
                  </span>

                </div>
              </div>
              <div *ngIf="password.invalid &&  password.touched" class="label__error border__error">
                <div *ngIf="password.errors?.['required']">
                  Password is required
                </div>
                <div *ngIf="password.errors?.['minlength']">
                  Minimum 8 characters is required
                </div>
              </div>
            </div>

            <br>
            <div class="f" *ngIf="!isOpen">
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-lock"></i>
                  </span>

                </div>

                <input [type]="hideConfirmPassword?'text':'password'"
                  class="border-0-login form-control-login input-border-none" formControlName="confirm_password"
                  name="confirm_password" placeholder="Confirm password">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon" (click)="hideconfirmPassword()">
                    <i [ngClass]="hideConfirmPassword?'fas fa-eye':'fas fa-eye-slash'"></i>
                  </span>

                </div>

              </div>
              <div *ngIf="confirm_password.invalid && confirm_password.touched" class="label__error border__error">
                <div *ngIf="confirm_password.errors?.['required']">
                  Confirm password is required
                </div>
                <div *ngIf="confirm_password.errors?.['minlength']">
                  Minimum 8 characters is required
                </div>
              </div>
            </div>

            <div class="label__error" *ngIf="setPasswordForm.errors?.['failedToMatch']">
              Password and Confirm password does not match
            </div>
            <div class="div" *ngIf="isOpen">
              <br>
            </div>

            <div class="mt-3" *ngIf="!isOpen">
              <button class="btn fontss text-white btn-block mt-1 btn-social" style="height:45px ;"
                (click)="submit()"><img class="" style="width : 24px; color:white;" *ngIf="isLoading2"
                  src="../../assets/images/Rolling-1s-200px (2).gif">
                <span class="mt-2" *ngIf="!isLoading2">Submit</span></button>
            </div>
            <div class="mt-3 " *ngIf="isOpen">
              <!-- <a class="" style="height:45px ;" (click)="backFunction()"><img
                  src="../../assets/images/icons8-back-arrow-48.png"></a> -->
              <button class="btn fontss text-white btn-block mt-1 btn-social" (click)="backFunction()"
                style="height:45px ;" (click)="submit()">
                Go back to login</button>
            </div>

          </form>
        </ng-container>

      </div>
    </div>

  </div>

</div>
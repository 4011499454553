import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sso-sam',
  templateUrl: './sso-sam.component.html',
  styleUrls: ['./sso-sam.component.css']
})
export class SSOSAMComponent implements OnInit {
  token: any;
  err: any ;
  isOpen: boolean = false;
  isCheckingUser: boolean = true;
  email: any
  constructor(private router: Router, private actRouter: ActivatedRoute, private auth: AuthService) { }

  ngOnInit(): void {
    this.actRouter.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.verifyAccountForDashboard()
  }

  /**
   * verify SSO function  for login user
   */
  verifyAccountForDashboard() {
    let verifyPayload = {
      'token': this.token
    }
    this.auth.ssoVerifyLogin(verifyPayload).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("token", JSON.stringify(res.Token));
        this.router.navigate(['/user/dashboard'])
      } else {
        this.statusCodeError(res)
      }
    })
  }

/**
 * Handles an error response with a specific status code.
 * This function is called when an HTTP request returns an error response with a specific status code.
 * @param {any} res - The error response object.
 * @returns {void}
 */
  statusCodeError(data: any) {
    // Check if the status code is 202, 401, or 402
    if (data.statusCode == 202 || data.statusCode == 401 || data.statusCode == 402) {
      // Set the error message and open the notification
      this.isCheckingUser = false
      this.err = data.body;
      this.isOpen = true
    }
  }


}

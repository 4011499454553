<div class="back">
    <br>

    <div class="" style="display: flex; justify-content:center ; align-items: center;">
        <div class="card " style="margin-top: 150px;">

            <div class="div" style="display:flex ; justify-content:center">
                <img src="../../assets/images/edyou-r.png" width="180px">
            </div>


            <ng-container *ngIf="isCheckingUser">
                <div class="loaderL" style="display: flex; justify-content: center; margin-top: 50px;">
                    <img class="" style="width : 50px; color:white;" src="../../assets/Rolling.gif">&nbsp; &nbsp; <b
                        style="font-weight: 600; margin-top: 5px;">Authenticating ....</b>
                </div>
                <br><br>
            </ng-container>

            <ng-container *ngIf="isOpen">
                <div style="display: flex; justify-content: center; margin-top: 50px;" class="error text-center">
                    <span style="font-weight: 600; font-size: 18px; color: #ff1e5a;">
                        {{err}}</span>
                </div>
                <div class="di" style="display: flex; justify-content: center; margin-top: 30px;">
                    <button class="btn  text-white" routerLink="/" style="height:45px ">
                        Go back to login</button>
                </div>
                <br><br>
            </ng-container>

        </div>

    </div>
</div>
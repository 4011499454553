import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgetPageComponent } from './forget-page/forget-page.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { TermsandConditionComponent } from './termsand-condition/termsand-condition.component';
import { ZoomLoginComponent } from './zoomMeeting/zoom-login/zoom-login.component';
import { HelppageComponent } from './helppage/helppage.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SSOSAMComponent } from './sso-sam/sso-sam.component';
import { TestpageComponent } from './testpage/testpage.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ForgetPageComponent,
    SetPasswordComponent,
    TermsandConditionComponent, 
    ZoomLoginComponent,
    HelppageComponent,
    SSOSAMComponent,
    TestpageComponent,


  ],
  imports: [
    BrowserModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

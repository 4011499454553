import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsand-condition',
  templateUrl: './termsand-condition.component.html',
  styleUrls: ['./termsand-condition.component.css']
})
export class TermsandConditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

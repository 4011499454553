export const API = {
  // dashboard
  learningDashboardData: "/Dashboard/Investor_Dashboard",
  //sso 
  ssoRedirectVerify: "/CommonAPI/Verify_Api",
  //
  login: "/crudInvestor/InvestorLogin",
  logout: "/crudInvestor/investorLogout",
  feedback: "/feedback/submitFeedback",
  deleteprofilepicture:"/CommonAPI/deleteprofilepicture",
  signUp: "/CommonAPI/Signup",

  forget: "/CommonAPI/forgotPasswordUser",
  setPassword: "/CommonAPI/setPasswordLinkUser",
  setPasswordTimer: "/CommonAPI/setOnlyOnceUser",
  getProfile: "/CommonAPI/userProfile",
  updateProfile: "/CommonAPI/userEditProfile",
  changePassword: "/CommonAPI/changePassword",
  avatarCounterLimit: "/CommonAPI/counter",
  mathsONOF: "/CommonAPI/Update_Mathematics_Module",
  speechSpedd: "/CommonAPI/Speech_Speed_API",
  //book a slot API 
  slotLogin: "/Slot/slotLogin",
  slotPage: "/Slot/slotPage",
  bookSlot: "/Slot/SlotUpdate",
  verifySlotBokking: "/Slot/slot_update_by_email_verification",
  //openAI API
  openAI: "/crudInvestor/openAIInvestor",
  OpenAIMathematics: "/openAI/OpenAIMathematics",
  // avatar
  uneeqAvatarToken: "/crudInvestor/initToken",
  // test series 
  getSeriesData: "/Question/Get_Question_For_Avatar",
  uneeqStartTest: "/uneeq/uneeqQuestionStart",
  recordTestDAta: "/crudInvestor/checkinvestorRecord",
  uneeqPromptMessege: "/uneeq/promptUser",
  InvestorUneeqQuestionInit: '/crudInvestor/InvestorUneeqQuestionInit',
  //
  getTopic: "/Topic/getTopicHeader",
  getByTopicID: "/Topic/getTopic",
  // presentation
  getPresentation: "/Presentation/get_Presentation_Header_Avatar",
  startPresentation: "/Presentation/Start_Presentation",
  start1Presentation: "/Presentation/PresentationEvents",
  PresentationSpeakAPI: "/Presentation/presentation_speak",
  audioCount: "/Presentation/audiodownload",
  recordLearningDAta: "/Presentation/Landing_Page_Presentation",
  getAllSubjectList: "/CheckRecords/Get_Subject",
  //zoom link setup API 
  // history conversation 
  investerLoginHistory: "/crudInvestor/investerLoginHistory",
  InvestorloginChatHistory: "/crudInvestor/InvestorloginChatHistory",
  saveEmailData: "/CommonAPI/InvestorZoomEmail",
  conversationSaveData: "/CommonAPI/apiResponse"

}
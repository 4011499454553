<div class="back">
	<div class="" style="display: flex; justify-content:center ; align-items: center;">
		<div class="speaks " style="margin-top: 30px;">

			<div class="row mt-1 p-3" style="justify-content:center">

				<div class="">
					<div class="div" style="display:flex ; justify-content:center">
						<img src="../../assets/images/edyou-r.png" width="180px">
					</div>
					<div class="">
						<div class="auth-form-light">
							<div class="brand-logo my-3 ">
								<h2 style="display:flex ; justify-content:center ;color:#0c7ea8 ;margin-top: -15px;">
									Login
								</h2>

								<!-- <button class="btn btn-social text-white "
									style="background-color: #0079a5; color:white" (click)="microsoftSSOLogin()">

									<object type="image/svg+xml"
										data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
										class="x-icon fa mt-2 ml-1"></object>
									Sign in with Sierra Canyon </button>

								<div class="orMain">
									<span>OR</span>
								</div>  -->

								<form class="mt-4 fontss" [formGroup]="form">

									<div class="input-group-login sombreado-input">
										<div class="input-group-prepend-login">
											<span class="input-group-text fondo-icon">
												<i class="fas fa-envelope"></i>
											</span>
										</div>
										<input type="text" class="border-0-login form-control-login input-border-none"
											formControlName="email" name="email" placeholder="Email" aria-label="Email">
									</div>
									<div *ngIf="email.invalid && (email.dirty || email.touched)"
										class="label__error border__error">
										<div *ngIf="email.errors?.['required']">
											Email is required
										</div>
										<div *ngIf="email.errors?.['pattern']">
											Invalid Email

										</div>
									</div>
									<!-- ------- -->
									<div class="input-group-login sombreado-input mt-3">
										<div class="input-group-prepend-login">
											<span class="input-group-text fondo-icon">
												<i class="fas fa-lock"></i>
											</span>

										</div>
										<input [type]="show?'text':'password'"
											class="border-0-login form-control-login input-border-none"
											formControlName="password" name="password" placeholder="Password"
											aria-label="password">
										<div class="input-group-prepend-login">
											<span class="input-group-text fondo-icon" (click)="hideShow()">
												<i [ngClass]="show?'fas fa-eye':'fas fa-eye-slash'"></i>
											</span>

										</div>
									</div>
									<div *ngIf="password.invalid && (password.dirty || password.touched)"
										class="label__error border__error">
										<div *ngIf="password.errors?.['required']">
											Password is required
										</div>

									</div>

									<a class="auth-link text-black fontss"
										style="cursor: pointer; float: right; margin-top: -1px;"
										(click)="forgetPassword()">Forgot Password?</a>

									<div class="form-group mt-5">
										<div class="input-group-login">
											<label class="containerr ">
												<label
													style="margin-top: -7px; font-size: 13px; width: 275px;line-height: 17px;">
													&nbsp;I agree to provide access to my microphone and to the edYOU <b
														style="color: blue;"> <a routerLink="./terms"
															target="_blank">Terms
															& Conditions</a></b>
												</label>
												<input type="checkbox" (change)=powerButton($event)
													formControlName="policy" name="policy">
												<span class="checkmark"></span>
											</label>


										</div>

										<div *ngIf="policy.invalid && (policy.dirty || policy.touched)"
											class="label__error border__error">
											<div *ngIf="policy.errors?.['required']">
												Please agree to T&Cs to continue
											</div>

										</div>
									</div>


									<div style="margin-top: -20px;">
										<button style="height:45px ;" (click)="login()"
											class="btnLogin  mt-1 btn-social ">
											<img class="" style="width : 24px; color:white;" *ngIf="isLoading"
												src="../../assets/images/Rolling-1s-200px (2).gif"> <span
												*ngIf="!isLoading">Login</span></button>
									</div>

									<!-- <div class="fontss"> -->
									<!-- <p>Don't have an Account? <a  class="auth-link text-black cursor-pointer" style="cursor:pointer ;" (click)="signUp()">Sign Up!</a></p> -->
									<!-- <div class="row" style="justify-content: space-around;">
										<a class="auth-link text-black "
											style="cursor: pointer; float: right; margin-top: -17px;"
											(click)="bookDemoLogin()">Schedule a time?</a>

										<a class="auth-link text-black "
											style="cursor: pointer; float: right; margin-top: -17px;"
											(click)="forgetPassword()">Forgot Password?</a>
									</div> -->
									
									<!-- <a class="auth-link text-black "
											style="cursor: pointer; float: right; margin-top: -17px;"
											(click)="forgetPassword()">Forgot Password?</a> -->

									<!-- </div> -->

									<div style="color: red;" class="error" *ngIf="isOpen">
										<span class="label__error border__error d-flex justify-content-center">
											{{err}}</span>
									</div>

								</form>
							</div>
						</div>


					</div>
				</div>

			</div>
		</div>
	</div>

</div>
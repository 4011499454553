<div class="back">


  <div class="" style="display: flex; justify-content:center ; align-items: center;">

    <div class="speaks " style="margin-top: 90px;">
      <form class="form-signin" [formGroup]="form">
        <div class="mobilMac">

          <div style="display:flex; justify-content:center">
            <img src="../../assets/images/edyou-r.png" width="210px">
          </div>
        </div>

        <div class="row mt-2 p-2" style="justify-content:space-around ;">

          <div class="ipadM">
            <div class="div toop" style="display:flex ; justify-content:center ; ">
              <img src="../../assets/images/edyou-r.png" width="210px">
            </div>
          </div>

          <div class=" mt-2">

            <div class="brand-logo">
              <h2 class="fontss" style=" color:#0c7ea8 ;margin-top: 27px; font-size: 26px;">Sign Up</h2>
            </div>
            <br>

            <div class="v fontss" *ngIf="step == 1">

              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login  form-control-login input-border-none"
                  formControlName="first_name" name="first_name" placeholder="First name" />
              </div>
              <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
                class="label__error border__error">
                <div *ngIf="first_name.errors?.['required']">
                  First name is required
                </div>
              </div>



              <br />
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-envelope"></i>
                  </span>
                </div>
                <input type="email" class="border-0-login form-control-login input-border-none" formControlName="email"
                  name="email" placeholder="Email" aria-label="Username" />
              </div>
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="label__error border__error">
                <div *ngIf="email.errors?.['required']">
                  Email is required
                </div>
                <div *ngIf="email.errors?.['pattern']">
                  Invalid Email

                </div>
              </div>

              <br />
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-birthday-cake"></i>
                  </span>
                </div>
                <input type="date" class="border-0-login form-control-login input-border-none" formControlName="Dob"
                  name="Dob" placeholder="DOB" aria-label="Username" />
              </div>
              <div *ngIf="Dob.invalid && (Dob.dirty || Dob.touched)" class="label__error border__error">
                <div *ngIf="Dob.errors?.['required']">
                  DOB is required
                </div>
              </div>
              <!-- -------- -->


            </div>

            <div class="v fontss" *ngIf="step == 2">
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-school"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login form-control-login input-border-none" formControlName="school"
                  name="school" placeholder="School/College" aria-label="Username" />
              </div>
              <div *ngIf="school.invalid && (school.dirty || school.touched)" class="label__error border__error">
                <div *ngIf="school.errors?.['required']">
                  School/College is required
                </div>
              </div>
              <!-- -------- -->

              <br>
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-city"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login border form-control-login input-border-none"
                  formControlName="state" name="state" placeholder="State" aria-label="State" />
              </div>
              <div *ngIf="state.invalid && (state.dirty || state.touched)" class="label__error border__error">
                <div *ngIf="state.errors?.['required']">
                  State is required
                </div>
              </div>

              <br>
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login form-control-login input-border-none" formControlName="zip"
                  name="zip" placeholder="Zip" aria-label="Zip" />
              </div>
              <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="label__error border__error">
                <div *ngIf="zip.errors?.['required']">
                  Zip is required
                </div>
              </div>
    
              <!-- -------- -->
            </div>
       
            <!-- -------- -->
          </div>

          <div class="">

            <div class="span extra"></div>

            <div class="c fontss" *ngIf="step == 1">

              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login form-control-login input-border-none"
                  formControlName="last_name" name="last_name" placeholder="Last name" aria-label="last_name" />
              </div>
              <div *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)"
                class="label__error border__error">
                <div *ngIf="last_name.errors?.['required']">
                  Last name is required
                </div>
              </div>

              <br>
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-phone"></i>
                  </span>
                </div>
                <input type="text" class="border-0-login form-control-login input-border-none" formControlName="contact"
                  name="contact" placeholder="Phone no" />
              </div>
              <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="label__error border__error">

                <div *ngIf="contact.errors?.['pattern']">
                  Only number are allowed
                </div>
                <div *ngIf="contact.errors?.['required']">
                  Phone no is required
                </div>
              </div>
              <!-- ----- -->
              <br />
              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-solid fa-venus-mars"></i>
                  </span>
                </div>
                <select formControlName="gender" name="gender"
                  class="border-0-login form-control-login input-border-none" formControlName="gender" name="gender">
                  <option value="">Select gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>

              </div>
              <div *ngIf="gender.invalid && (gender.dirty || gender.touched)" class="label__error border__error">
                <div *ngIf="gender.errors?.['required']">
                  Gender is required
                </div>
              </div>
              <!-- -------- -->
            </div>

            <div class="b fontss" *ngIf="step == 2">

              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-flag"></i>
                  </span>
                </div>
                <!-- <input type="text" class="border-0-login form-control-login input-border-none" formControlName="country" name="country" placeholder="Country"
                aria-label="Country" /> -->
                <select formControlName="country" name="country"
                  class="border-0-login form-control-login input-border-none" formControlName="country" name="country">
                  <option value="">Select country</option>
                  <option style="height: 250px;" value="{{zones.name}}" *ngFor="let zones of countryName">
                    {{zones.name}}</option>
                </select>
              </div>
              <div *ngIf="country.invalid && (country.dirty || country.touched)" class="label__error border__error">
                <div *ngIf="country.errors?.['required']">
                  Country is required
                </div>
              </div>
              <br>

              <div class="input-group-login sombreado-input">
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <input [type]="show?'text':'password'" class="border-0-login form-control-login input-border-none"
                  formControlName="password" name="password" placeholder="Password" aria-label="Password" />
                <div class="input-group-prepend-login">
                  <span class="input-group-text fondo-icon" (click)="showHide()">
                    <i [ngClass]="show?'fas fa-eye':'fas fa-eye-slash'"></i>
                  </span>
                </div>
              </div>
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="label__error border__error">
                <div *ngIf="password.errors?.['required']">
                  Password is required
                </div>
              </div>

              <br>
              <br>
            </div>
            <br>
            <span class="text-center">
              <div class="row" style="display: flex;justify-content:space-around">
                <button class="fondo-color-signup fontss ml-2 button-signup" style="width: 140px;" (click)="previous()">
                  <strong>Previous</strong>
                </button>

                <button class="fondo-color-signup fontss button-signup" *ngIf="step == 1" style="width: 140px;"
                  (click)="next()">
                  <strong>Next</strong>
                </button>
                <button class="button-signup fontss fondo-color-signup" (click)="register()" style="width: 140px;"
                  *ngIf="step == 2">
                  <img class="" style="width : 24px; color:white;" *ngIf="isLoading"
                    src="../../assets/images/Rolling-1s-200px (2).gif"> <span
                    *ngIf="!isLoading"><strong>Signup</strong></span>
                </button>
              </div>
            </span>
            <div style="color: red;" class="error" *ngIf="isOpen">
              <span class="label__error border__error"> {{err}}</span>
            </div>
            <div *ngIf="!err">
              <div style="color: green ;font-size: 17px;" class="error" *ngIf="isOpen2">
                <br>
                <span class=""> {{err2}}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>

 
    <script>
    window.onload = function () {
    textarea = EqEditor.TextArea.link('latexInput')
    .addOutput(new EqEditor.Output('output'))
    .addHistoryMenu(new EqEditor.History('history'));
    
    EqEditor.Toolbar.link('toolbar').addTextArea(textarea);
    }
    </script>

 
    <div id="equation-editor">
    <div id="history"></div>
    <div id="toolbar"></div>
    <div id="latexInput" placeholder="Write Equation here..."></div>
    <div id="equation-output">
    <img id="output">
    </div>
    </div>

    
<div class="col-md-10 col-md-6">
    <br>
    <span style="font-size: 18px; font-weight: 800;">If you’ve previously denied access to the mic, or you have it
        disabled for all sites, you can selectively
        enable microphone access for site.</span>
    <br>
    <h2 class="mt-3">Enabling Microphone Access</h2>
    
    <h2 class="mt-3">Google Chrome</h2>

    <div class="div" style="display: flex; justify-content: center;">
        <img src="../../../../assets/microphoneImage.png"  style=" width: 400px;">
    </div>

    <div class="div" style="display: flex; justify-content: center;">
        <b>OR</b>
        </div>
    <ol>
        <li>In the top right of Chrome,  <b style="font-weight: 900;font-size: 16px;"> click on Three dots &gt; Settings option</b>.</li>
        <li>Click <strong style="font-weight: 900; font-size: 16px;">Privacy and security &gt; click on Site settings &gt; Microphone</strong>.</li>
        <li>Select the option you want as your default setting.</li>
        <li>Review your blocked and allowed sites.</li>
        <li>If you’ve already blocked mic access to edYOU app, under “Not allowed,” select your edYOU app URL and change
            the microphone permission to “Allow.”</li>
    </ol>

    <h2>Safari</h2>
    <div class="div" style="display: flex; justify-content: center;">
        <img src="../../../../assets/safariMicrophone.png"  style=" width: 400px;">
    </div>
    <br>
    <div class="div" style="display: flex; justify-content: center;">
        <b>OR</b>
        </div>

    <ol>
        <li>Click on Safari on top menu and open <strong style="font-weight: 900; font-size: 16px;">settings</strong>.</li>
        <li>Select Websites from upper menu and  <strong style="font-weight: 900; font-size: 16px;">then Microphone from the left menu.</strong>.</li>
        <li>From the list of websites, <strong style="font-weight: 900; font-size: 16px;">select your edYOU app URL and change the permission to “Allow.”</strong>.</li>
        <li>Also set "When visiting other websites" to "Ask"</li>
    </ol>

    <h2>Firefox</h2>
    <div class="div" style="display: flex; justify-content: center;">
        <img src="../../../../assets/microphoneFirefox.png"  style=" width: 400px;">
    </div>
    <br>
    <div class="div" style="display: flex; justify-content: center;">
       
        <b>OR</b>
        </div>
    <ol>
        <li>Click on the hamburger icon (three horizontal lines) in the top-right corner.</li>
        <li>Select <strong style="font-weight: 900; font-size: 16px;">"Settings" </strong>from the dropdown menu.  &gt;   <strong style="font-weight: 900; font-size: 16px;"> Click on "Privacy & Security." </strong>.</li>
        <li>Scroll down to the Permissions section.</li>
        <li>If you’ve already blocked mic access to edYOU app, select your edYOU app URL from the list of sites and
            change the status to “Allow.”</li>
    </ol>

    <p>Alternatively, you can use Firefox’s address bar to provide microphone access:</p>
    <ol>
        <li>Click the <b style="font-weight: 900; font-size: 16px;">permissions icon</b> that displays in the address
            bar of your edYOU app on the left.</li>
        <li>Click the <strong style="font-weight: 900; font-size: 16px;">X</strong> next to Blocked or Blocked
            Temporarily to clear the status so that Firefox will ask again for permission when you next visit your edYOU
            app.</li>
    </ol>

    <br><br><br>

</div>
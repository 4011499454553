import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-zoom-login',
  templateUrl: './zoom-login.component.html',
  styleUrls: ['./zoom-login.component.css']
})
export class ZoomLoginComponent implements OnInit {

  isLoading: boolean = false
  // submitted: boolean = false;
  err: any;
  isOpen: boolean = false;
  constructor(private service: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  // form group
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    // password: new FormControl('', Validators.required),
  })



  get email() {
    return this.form.controls['email'];
  }

  get password() {
    return this.form.controls['password'];
  }



  reset() {
    window.location.reload()
  }


  // activate  validation on submit
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  login() {
    localStorage.setItem("ZoomLogin", this.form.value.email);
    this.router.navigate(['/meetingLink'])


  }

}

<div class="div mt-3" style="display:flex ; justify-content:center">
    <br><br>
    <h1>TERMS OF SERVICE</h1>

</div>

<div class="col-md-12">
    <p><span style="font-weight: 400;">Welcome to EdYou&nbsp; PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE ACCESSING
            OR USING THIS APPLICATION.</span></p>
    <p><span style="font-weight: 400;">EdYou Technologies Inc., a Delaware corporation doing business as &ldquo;EdYou
            Technologies&rdquo; (&ldquo;EDYOU&rdquo;, &ldquo;us&rdquo; or &ldquo;we&rdquo;) owns and operates this
            website and any other web services, application, or products offered by us now or in the future, including
            out avatar application.&nbsp; By accessing, using, downloading, viewing this website or application through
            any of the URLs including, edyou.com</span><span style="font-weight: 400;">,&nbsp;</span><span
            style="font-weight: 400;">or any of its services, application or Content (defined below) (collectively, the
            &ldquo;Application&rdquo;), you hereby consent and agree to these terms and conditions (&ldquo;Terms of
            Use&rdquo;). The Terms of Use and Privacy Policy (collectively, the &ldquo;Terms&rdquo;) govern your use of
            the Application, use and access of other free materials and resources provided by the Application, and any
            other services or Products we make available on this Application (collectively, the &ldquo;Services&rdquo;).
            These Terms constitute a legally binding agreement made by and between us and the user of this Application
            (personally and, if applicable, on behalf of the entity for whom you are using the Application;
            collectively, &ldquo;you&rdquo;).</span></p>
    <p><span style="font-weight: 400;">BY ACCESSING OR USING ANY PART OF THE APPLICATION OR SERVICES, YOU AGREE THAT YOU
            HAVE READ, UNDERSTOOD AND AGREED TO BE BOUND BY THESE TERMS, WHICH CONTAIN A TERMS OF SALE AGREEMENT, AN
            ARBITRATION AGREEMENT, A WAIVER OF CLASS-ACTION RIGHTS, AND LIABLIITY LIMITATIONS.&nbsp;</span></p>
    <p><span style="font-weight: 400;">If you do not agree to these Terms and to follow all applicable laws, then please
            cease access or use of the Application and Services immediately.</span></p>
    <p><span style="font-weight: 400;">If you have any questions about these Terms, please contact us by email at
            info@edyou.com.</span></p>
    <p><span style="font-weight: 400;">If you access any EDYOU Application on a social media network (such as, without
            limitation, Facebook, Twitter, Instagram, or Pinterest), you also consent to and agree to abide by the terms
            and conditions of that social media network.</span></p>
    <ol>
        <li><strong>Content on Our Application</strong></li>
    </ol>
    <p><strong>Intellectual Property Rights.</strong><span style="font-weight: 400;">&nbsp;You agree that the
            Application itself, as well as all content, photographs, sound or videos, media, images, formulas, graphics,
            webinars, training materials, products, services and/or other information and materials, and selection and
            arrangements thereof is copyrighted work under the United States and other copyright laws, and is the
            property of or licensed by EDYOU, made available on the Application or any information, materials, and
            content transferred via a downloadable file or link by us or other third parties (collectively the
            &ldquo;Content&rdquo;). All trademarks, service marks, and trade names (collectively, the
            &ldquo;Marks&rdquo;) are trademarks or registered trademarks of and are proprietary to EDYOU or other
            respective owners that have granted EDYOU the right to use such Marks. Subject to your compliance with these
            Terms, we hereby grant you a limited, non-exclusive, non-transferable, and non-sublicensable, and revocable
            right to access, view, and use the Application solely for your personal purposes and non-commercial use, and
            as we otherwise intend. EDYOU reserves the right to monitor the Services and Content for the purpose of
            determining that your usage complies with these Terms.&nbsp;</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><span style="font-weight: 400;">You may not modify, publish, transmit, participate in the transfer or sale of,
            reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit
            any of the materials or Content on our Application in whole or in part, other that as necessary for your own
            personal non-commercial use, without our written consent.&nbsp; Modification of the Content or use of the
            Content for any other purpose is a violation of the copyright and other proprietary rights of EDYOU, as well
            as other authors who created the materials, and may be subject to monetary damages and penalties.</span></p>
    <p><strong>Third-Party Content.</strong><span style="font-weight: 400;">&nbsp;Our Application contains Content that
            we create as may also include Content provided by third parties.&nbsp; We do not monitor, we do not endorse,
            and we are not liable for any third-party content.&nbsp; There may be some inadvertent accuracies or errors
            in the content and we do not guarantee the accuracy, integrity, completeness or quality of the content on
            our Application or located at third party URLs that may be posted on our Application.&nbsp; EDYOU is not
            responsible or the content on any linked site or any link contained in a linked site.&nbsp; We do not
            endorse or accept responsibility for the content of such third-party sites.</span></p>
    <p><strong>Third-Party Services.</strong><span style="font-weight: 400;">&nbsp; Third parties may offer their
            services directly to you through the Application.&nbsp; In such case, you may be required to agree to the
            third party&rsquo;s terms of service and/or privacy policy to use the service.&nbsp; EDYOU will not be
            liable in any way for the acts or omissions of such third party, the terms of service or privacy policy or
            its failure to adhere to its terms of services or privacy policy, or any loss, damages, liability or
            expenses (including attorneys&rsquo; fees) that you may incur arising from or related to such third
            party&rsquo;s services or products.</span></p>
    <ol start="2">
        <li><strong>Your Conduct on Our Application</strong></li>
    </ol>
    <p><strong>Eligibility.</strong><span style="font-weight: 400;">&nbsp;To use the Application, you must be, and
            represent and warrant that you are, of legal age (18 years of age or older) and competence. By using the
            Application on behalf of any third party, you are representing to us that you are an authorized
            representative of that third party and that your use of the Application constitutes that third party&rsquo;s
            acceptance of these Terms. In addition, if you have been previously prohibited from accessing the
            Application or the webApplication of any of our affiliates, you are not permitted to access the
            Application.</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><strong>Account Information.</strong><span style="font-weight: 400;">&nbsp;If you create an account, any
            information that you choose to provide us must be true, accurate, current, and complete. You are entirely
            responsible for maintaining the confidentiality of your password and account. You are entirely responsible
            for any and all activities that occur under your account. Your account is non-transferrable. You cannot
            sell, combine, or otherwise share it with any other person. Any violation of these Terms, including, without
            limitation, failure to maintain updated and correct information about your account or cause your account to
            fall out of good standing and we may cancel your account in our sole discretion. When you register with
            EDYOU and/or this Application, you expressly consent to receive any notices, announcements, agreements,
            disclosures, reports, documents, communications concerning new products or services, or other records or
            correspondence from EDYOU.</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><strong>Feedback.</strong><span style="font-weight: 400;">&nbsp;If you send comments or suggestions about the
            Application to EDYOU, including, but not limited to, notes, text, drawings, images, designs or computer
            programs, such submissions shall become, and shall remain, the sole property of EDYOU. No submission shall
            be subject to any obligation of confidence on the part of EDYOU.&nbsp; EDYOU shall exclusively own all
            rights to (including intellectual property rights thereto), and shall be entitled to unrestricted use,
            publication, and dissemination as to all such submissions for any purpose, commercial or otherwise without
            any acknowledgment or compensation to you.</span></p>
    <p>&nbsp;</p>
    <p><strong>Prohibited Conduct.</strong><span style="font-weight: 400;">&nbsp;You agree not to copy or imitate the
            appearance, design or style of our Application or any Content. The technology and software underlying our
            Application and the Services are the property of EDYOU, our affiliates and/or our business partners. You
            agree that you will not use our Application or its Content to take any of the following actions:</span></p>
    <ul>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights
                of EDYOU or any other person or entity;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-weight: 400;">Use
                the Service or Application commercially;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Reverse engineer, decompile, tamper with or disassemble the technology used to
                provide the Services or Application (except as and only to the extent any foregoing restriction is
                prohibited by a non-waivable provision of applicable law);</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Interfere with or damage the Services, Application, or underlying any
                technology;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Impersonate or misrepresent your identity or affiliation;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Attempt to obtain unauthorized access to the Services or Application;</span>
        </li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Violate, misappropriate or infringe a third party&rsquo;s intellectual
                property or other right, or any social media platform terms;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Violate any law, rule, or regulation;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Transmit executable programming or corrupted files of any kind, including
                viruses, spyware, trojan horses, Easter eggs or any other similar software or programs that may damage
                or adversely affect the operation of another person&apos;s computer, our Application, software or
                hardware, third party websites or telecommunications equipment;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Falsify or delete any author attributions, legal or other proper notices or
                proprietary designations or labels of the origin or the source of software posted or contributed content
                or other material; or</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Engage in any illegal activities.</span></li>
    </ul>
    <p><span style="font-weight: 400;">Use of our Application is subject to existing laws and legal process. Nothing
            contained in these Terms will limit our right to comply with governmental, court, and law-enforcement
            requests or requirements relating to your use of our Application.</span></p>
    <ol start="3">
        <li><strong>Privacy Policy</strong></li>
    </ol>
    <p><span style="font-weight: 400;">All of the information that we collect from you, such as your e-mail address and
            related personal information and credit card information, is subject to our privacy policy. EDYOU&rsquo;s
            privacy policy (which describes how we collect, use and disclose your data and your consent to such
            collection, use, and disclosure) is incorporated into and is a part of these Terms.</span></p>
    <ol start="4">
        <li><strong>DCMA Copyright Infringement Takedown Policy</strong></li>
    </ol>
    <p><strong>Infringement Notification.</strong><span style="font-weight: 400;">&nbsp;EDYOU respects the rights of
            others and we expect users of our Application and Services to do the same. These Terms prohibit the
            infringement of the copyrights of others, and it is also our policy that we may remove, suspend, terminate
            access, or take other appropriate action against repeat offenders. We may also remove content that in our
            sole discretion appears to infringe the intellectual property rights of others.</span></p>
    <p><strong>How to File an Infringement Notification.</strong><span style="font-weight: 400;">&nbsp;If you have
            evidence, know, or have a good faith belief that content residing on or accessible through our Application
            infringes a copyright which you own or for which you are a designated agent, please send a notice of
            infringement by email to EDYOU:</span></p>
    <p><strong>GDPR</strong></p>
    <p><span style="font-weight: 400;">Email Address: info@edyou.com.</span></p>
    <p><span style="font-weight: 400;">Send such notice with the information that sets forth the items specified
            below:</span></p>
    <ul>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Identify the copyrighted work claimed to have been infringed. If multiple
                copyrighted works are covered by a single notification, provide a representative list of such
                works.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Identify the material that is claimed to be infringing or to be the subject of
                infringing activity. Include information reasonably sufficient to permit EDYOU to locate the material.
                Please provide a URL and screenshots for each item. Include the specific asset(s) or page(s) that you
                claim to be infringing. Say &ldquo;entire work&rdquo; ONLY if all assets/pages in a collection/document
                are infringing.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Include details of your claim to the material, or your relationship to the
                material&rsquo;s copyright holder.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Provide your full name, address, and telephone number should we need to
                clarify your claim.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Provide a working email address where we can contact you to confirm your
                claim.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-weight: 400;">If
                true, include the following statement: &ldquo;I have a good faith belief that use of the copyrighted
                materials described above as the allegedly infringing web pages is not authorized by the copyright
                owner, its agent, or the law.&rdquo;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-weight: 400;">If
                true, include the following statement: &ldquo;I swear, under penalty of perjury, that the information in
                the notification is accurate and that I am the copyright owner or am authorized to act on behalf of the
                copyright owner to make this complaint.&rdquo;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-weight: 400;">Sign
                the document, physically or electronically.</span></li>
    </ul>
    <p><span style="font-weight: 400;">ANY NOTICE THAT DOES NOT COMPLY WITH THE REQUIREMENTS OF TITLE 17, UNITED STATES
            CODE, SECTION 512(c)(3) WILL NOT RECEIVE A RESPONSE. NOTHING IN THIS POLICY IS INTENDED TO EXPAND OR
            SUPPLEMENT THE LEGAL RIGHTS, PROCEDURES AND REMEDIES AUTHORIZED AND GRANTED UNDER THE DMCA. Please note that
            you may be liable for damages, including but not limited to costs and attorneys&rsquo; fees, under the DMCA
            if you knowingly materially misrepresent: (a) that material on the Application infringes upon your
            copyright; or (b) that material on the Application was removed or disabled by mistake or misidentification.
            If a user is found to be an infringer of the copyright rights of others, EDYOU may terminate access to the
            user&rsquo;s account.</span></p>
    <ol start="5">
        <li><strong>Disputes</strong></li>
    </ol>
    <p><span style="font-weight: 400;">Subject to applicable law, you and EDYOU agree that any dispute that has arisen
            or may arise between us relating in any way to your use of or access to the Services or Application, any
            breach, enforcement, or termination of these Terms, or otherwise relating to EDYOU in any way will be
            resolved in accordance with the provisions set forth in this Section.</span></p>
    <p><strong>Informal Resolution.</strong><span style="font-weight: 400;">&nbsp;If you have any dispute with us, you
            agree that before taking any formal action, you will contact us at info@edyou.com, provide a brief, written
            description of the dispute and your contact information (including your username, if your dispute relates to
            an account) and allow sixty (60) days to pass, during which we will attempt to reach an amicable resolution
            of any issue.</span></p>
    <p><strong>Arbitration.</strong><span style="font-weight: 400;">&nbsp;These Terms and each of its parts evidence a
            transaction involving interstate commerce, and the Federal Arbitration Act applies in all cases and governs
            the interpretation and enforcement of the arbitration rules and arbitration proceedings. Any Covered Matters
            must be asserted individually in binding arbitration administered by the American Arbitration Association
            (&ldquo;AAA&rdquo;) in accordance with its Commercial Arbitration Terms and the AAA Supplementary Procedures
            for Consumer-Related Disputes (including, without limitation, utilizing desk, phone or video conference
            proceedings where appropriate and permitted to mitigate costs of travel). The arbitrator shall not conduct
            any form of class or collective arbitration nor join or consolidate claims by or for individuals. The
            arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve
            any dispute relating to the interpretation, applicability, enforceability or formation of these Terms,
            including, but not limited to, any claim that all or any part of these Terms is void or voidable or a
            particular claim is subject to arbitration. Judgment on the award rendered by the arbitrator may be entered
            in any court of competent jurisdiction.</span></p>
    <p><strong>Award.</strong><span style="font-weight: 400;">&nbsp;For matters where the relief sought is over $5,000,
            the arbitrator&rsquo;s decision will include the essential findings and conclusions upon which the
            arbitrator based the award. The arbitrator will decide the substance of all claims in accordance with
            applicable law, including recognized principles of equity, and will honor all claims of privilege recognized
            by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users but is
            bound by rulings in prior arbitrations involving the same EDYOU user to the extent required by applicable
            law. The arbitrator&rsquo;s award shall be final and binding and judgment on the award rendered by the
            arbitrator may be entered in any court having jurisdiction thereof. THE ARBITRATOR MAY AWARD RELIEF
            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
            RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY&rsquo;S INDIVIDUAL
            CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS.</span></p>
    <p><strong>Exceptions.</strong><span style="font-weight: 400;">&nbsp;There are only two exceptions to this
            arbitration agreement:</span></p>
    <p><span style="font-weight: 400;">First, if a party reasonably believes that the other party has in any manner
            infringed or threatened to infringe the intellectual property rights of the other party, the party who owns
            the intellectual property rights may seek injunctive or other appropriate interim relief in any court of
            competent jurisdiction.</span></p>
    <p><span style="font-weight: 400;">Second, any claim of $500 or less may, at the option of the claiming party, be
            resolved in small claims court within the United States, if the claim and the parties are within the
            jurisdiction of the small claims court and so long as the matter remains in such court and advances only on
            an individual (non-class, non-representative) basis.</span></p>
    <p><strong>Costs of Arbitration.</strong><span style="font-weight: 400;">&nbsp;Payment of all filing,
            administration, and arbitrator fees will be governed by the AAA&rsquo;s rules, unless otherwise stated in
            this agreement to arbitrate. If the value of the relief sought is $5,000 or less, at your request, EDYOU
            will reimburse you for all filing, administration, and arbitrator fees associated with the arbitration
            following the earlier of the arbitrator&rsquo;s decision or settlement. In the event the arbitrator
            determines the claim(s) you assert in the arbitration to be frivolous, EDYOU is relieved of its obligation
            to reimburse you for any fees associated with the arbitration.</span></p>
    <p><strong>Future Amendments to the Agreement to Arbitrate.</strong><span
            style="font-weight: 400;">&nbsp;Notwithstanding any provision in the Terms to the contrary, you and we agree
            that if we make any amendment to this agreement to arbitrate in the future, that amendment shall not apply
            to any claim that was filed in a legal proceeding against EDYOU prior to the effective date of the
            amendment. The amendment shall apply to all other disputes or claims governed by the agreement to arbitrate
            that have arisen or may arise between you and EDYOU. If you do not agree to these amended terms, you may
            close your account within thirty (30) days of the posting or notification and you will not be bound by the
            amended terms.</span></p>
    <p><strong>Judicial Forum for Legal Disputes.</strong><span style="font-weight: 400;">&nbsp;Unless you and we agree
            otherwise, in the event that the agreement to arbitrate above is found not to apply to you or to a
            particular claim or dispute, either as a result of your decision to opt out of the agreement to arbitrate,
            as a result of a decision by the arbitrator or a court order or because you have chosen to file an eligible
            lawsuit in small claims court, you agree that any claim or dispute that has arisen or may arise between you
            and EdYou must be resolved exclusively by a state or federal court located in California. You and EDYOU
            agree to submit to the personal jurisdiction of the courts located within California for the purpose of
            litigating all such claims or disputes.</span></p>
    <p><strong>OPT-OUT.</strong><span style="font-weight: 400;">&nbsp;IF YOU ARE A NEW EDYOU USER, YOU CAN CHOOSE TO
            REJECT THE AGREEMENT TO ARBITRATE PROVISION (&quot;OPT-OUT&quot;) BY EMAILING US AN OPT-OUT NOTICE TO
            INFO@EDYOU.COM (&quot;OPT-OUT NOTICE&quot;): EDYOU. THE OPT-OUT NOTICE MUST BE RECEIVED NO LATER THAN THIRTY
            (30) DAYS AFTER THE DATE YOU ACCEPT THE TERMS OF SERVICE FOR THE FIRST TIME. IF YOU ARE NOT A NEW EDYOU
            USER, YOU HAVE UNTIL THIRTY (30) DAYS AFTER THE POSTING OF THE NEW TERMS OF SERVICE TO SUBMIT AN ARBITRATION
            OPT-OUT NOTICE.</span></p>
    <p><span style="font-weight: 400;">Procedure. In order to opt-out, you must email your name, address (including
            street address, city, state, and zip code), and email address(es) associated with your Account(s) to which
            the opt-out applies and an unaltered digital image of a valid driver&rsquo;s license which matches the name
            on your account to: info@edyou.com.</span></p>
    <p><span style="font-weight: 400;">This procedure is the only way you can opt out of the agreement to arbitrate. If
            you opt out of the agreement to arbitrate, all other parts of the agreement and its Disputes Section will
            continue to apply to you. Opting out of this agreement to arbitrate has no effect on any previous, other, or
            future arbitration agreements that you may have with us.</span></p>
    <p><strong>WAIVER.</strong><span style="font-weight: 400;">&nbsp;BY AGREEING TO THESE TERMS, YOU HEREBY IRREVOCABLY
            WAIVE ANY RIGHT YOU MAY HAVE TO A COURT TRIAL (OTHER THAN SMALL CLAIMS COURT AS PROVIDED BELOW) OR TO SERVE
            AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO
            PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING FILED
            AGAINST US AND/OR RELATED THIRD PARTIES.</span></p>
    <ol start="6">
        <li><strong>Indemnification</strong></li>
    </ol>
    <p><span style="font-weight: 400;">You hereby agree to indemnify, defend and hold EDYOU and all of our officers,
            directors, mangers, members, employees, agents, information providers, affiliates, partners, and licensors
            (&ldquo;EDYOU Party,&rdquo; or collectively, the &ldquo;EDYOU Parties&rdquo;) harmless from and against any
            and all liability, claims, damages, losses, costs, and expenses, including attorneys&apos; fees, incurred by
            any EDYOU Party arising from, related to, or in connection with (a) a violation of any provision of these
            Terms by you; or (b) arising from, related to, or connected with your violation of the rights of EDYOU or
            any other person or entity. We may, in our sole and absolute discretion, control the disposition of any such
            claim at your sole cost and expense. You may not settle any such claim without our express written consent.
            This defense and indemnification obligation is intended to extend to the fullest extent permitted by law and
            will survive these Terms and your use of the Application.</span></p>
    <ol start="7">
        <li><strong>Warranties and Disclaimers</strong></li>
    </ol>
    <p><strong>Service Outages and Force Majeure.</strong><span style="font-weight: 400;">&nbsp;Unless you have greater
            rights in a separate signed agreement with us, we disclaim to the fullest extent permitted by law any
            service outages that are caused by our maintenance on the servers or the technology that underlies our
            Application, failures of our service providers (including telecommunications, hosting, and power providers),
            computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war,
            civil disturbance, or any other cause beyond our reasonable control. Under no circumstances shall EDYOU or
            its licensor or service providers be held liable for any delay or failure in performance resulting directly
            or indirectly from an event beyond its reasonable control. This provision is not intended to disclaim
            liability that EDYOU may not disclaim under law.</span></p>
    <p><span style="font-weight: 400;">USE OF APPLICATION AND CONTENT IS AT YOUR OWN RISK. WE DO NOT WARRANT THAT OUR
            APPLICATION WILL BE UNINTERRUPTED OR ERROR FREE. IN ADDITION, WE DO NOT MAKE ANY WARRANTY AS TO THE CONTENT
            ON OUR APPLICATION. OUR APPLICATION AND CONTENT ARE DISTRIBUTED ON AN &quot;AS IS, AS AVAILABLE&quot; BASIS
            TO THE FULLEST EXTENT PERMITTED BY LAW. ANY MATERIAL THAT YOU DOWNLOAD OR OTHERWISE OBTAIN THROUGH OUR
            APPLICATION IS DONE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY POTENTIAL
            DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM YOUR DOWNLOAD OF ANY SUCH MATERIAL.
            NEITHER WE NOR ANY OF OUR AFFILIATES OR BUSINESS PARTNERS MAKES ANY WARRANTY THAT (i) OUR APPLICATION, AND
            SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (ii) OUR APPLICATION WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR APPLICATION WILL BE
            ACCURATE OR RELIABLE, AND (iv) ANY ERRORS WILL BE CORRECTED. NEITHER WE NOR ANY OF OUR AFFILIATES OR
            BUSINESS PARTNERS MAKE ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
            WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
            PURPOSE, WITH RESPECT TO OUR APPLICATION, ANY CONTENT, OR ANY OF OUR SERVICES, TOOLS, PRODUCTS, OR
            PROPERTIES. YOU EXPRESSLY AGREE THAT YOU WILL ASSUME THE ENTIRE RISK AS TO THE QUALITY AND THE PERFORMANCE
            OF OUR APPLICATION AND THE ACCURACY OR COMPLETENESS OF ITS CONTENT.&nbsp; EDYOU ASSUMES NO RESPONSIBILITY
            FOR AND DISCLAIMS ALL LIABILITY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW FOR ANY SUCH INACCURACIES,
            ERRORS OR OMISSIONS. NEITHER WE NOR OUR AFFILIATES OR BUSINESS PARTNERS WILL BE LIABLE FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE OUR
            APPLICATION, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS PROVISION IS NOT INTENDED
            TO DISCLAIM LIABILITY THAT EDYOU MAY NOT DISCLAIM UNDER APPLICABLE LAW.</span></p>
    <p><span style="font-weight: 400;">NO RESPONSIBILITY FOR THIRD-PARTY SERVICES AND MATERIALS. EDYOU DOES NOT WARRANT,
            ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
            PARTY THROUGH THE APPLICATION OR ANY WEBSITE FEATURED OR LINKED TO THROUGH THE APPLICATION, AND EDYOU WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
            PROVIDERS OF PRODUCTS OR SERVICES. EDYOU WILL NOT BE LIABLE FOR THE OFFENSIVE OR ILLEGAL CONDUCT OF ANY
            THIRD PARTY. YOU VOLUNTARILY ASSUME THE RISK OF HARM OR DAMAGE FROM THE FOREGOING. THE FOREGOING LIMITATIONS
            WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</span>
    </p>
    <p><span style="font-weight: 400;">NEW JERSEY RESIDENTS. TO NEW JERSEY RESIDENTS, THE PROVISIONS ABOVE ARE INTENDED
            TO BE AS BROAD AND INCLUSIVE AS PERMITTED BY THE LAW OF THE STATE OF NEW JERSEY ONLY.</span></p>
    <ol start="8">
        <li><strong>Limitation of Liability</strong></li>
    </ol>
    <p><strong>NO CONSEQUENTIAL DAMAGES.</strong><span style="font-weight: 400;">&nbsp;IN NO EVENT, AS PERMITTED BY THE
            FULLEST EXTENT OF APPLICABLE LAW, WILL EDYOU, AND EDYOU PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES RESULTING FROM THE PERFORMANCE, USE OF OR THE INABILITY TO USE
            THE APPLICATION, SERVICES, CONTENT OR PRODUCTS, EVEN IF EDYOU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, VIOLATION OF
            STATUTE OR OTHERWISE. THIS PROVISION IS NOT INTENDED TO EXCLUDE LIABILITY THAT EDYOU MAY NOT EXCLUDE UNDER
            APPLICABLE LAW.</span></p>
    <p><strong>OUR LIABILITY IS LIMITED.</strong><span style="font-weight: 400;">&nbsp;IN ANY EVENT, OUR AGGREGATE
            LIABILITY WILL NOT EXCEED THE AMOUNT PAID FOR THE PRODUCTS TO WHICH THE CLAIM RELATES OR, IF THE CLAIM DOES
            NOT RELATE TO A PRODUCT, $100. THIS PROVISION IS NOT INTENDED TO EXCLUDE LIABILITY THAT EDYOU MAY NOT
            EXCLUDE UNDER APPLICABLE LAW.</span></p>
    <p><strong>NEW JERSEY RESIDENTS.</strong><span style="font-weight: 400;">&nbsp;TO NEW JERSEY RESIDENTS, THE
            PROVISIONS OF THIS PARAGRAPH 9 ARE INTENDED TO BE AS BROAD AND INCLUSIVE AS PERMITTED BY THE LAW OF THE
            STATE OF NEW JERSEY ONLY.</span></p>
    <ol start="9">
        <li><strong>Termination; Survival</strong></li>
    </ol>
    <p><strong>Term.</strong><span style="font-weight: 400;">&nbsp;These Terms are effective unless and until terminated
            by you or us. We may, in our sole and absolute discretion and without any liability, modify, suspend or
            discontinue any aspect of the Application, temporarily or permanently, at any time and without prior
            notice.</span></p>
    <p><strong>Suspension and Termination.</strong><span style="font-weight: 400;">&nbsp;We may deny you access to all
            or part of the Application at any time for any reason (including if you violate these Terms, as determined
            in our sole and absolute discretion) or no reason at all.</span></p>
    <p><strong>Survival.</strong><span style="font-weight: 400;">&nbsp;If we terminate your right to access the
            Application, these Terms will terminate and all rights you have to access the Application will immediately
            terminate. The following provisions will survive termination: Intellectual Property, Indemnification,
            Payment Obligations, Warranties and Disclaimers, Limitations of Liability, Dispute and any&nbsp;</span><span
            style="font-weight: 400;">and all others that by their sense and context are intended to survive the
            termination or expiration of the Agreement shall survive</span><span style="font-weight: 400;">.</span></p>
    <ol start="10">
        <li><strong>General Terms</strong></li>
    </ol>
    <p><strong>No Waiver; Severability.</strong><span style="font-weight: 400;">&nbsp;Our failure to exercise or enforce
            any right or provision of these Terms will not constitute a waiver of such right or provision, and our
            failure to exercise or enforce any right or remedy in these Terms does not waive that right or remedy. The
            provisions of these terms are intended to extend to the fullest extent permitted by law. No waiver of any
            term of these Terms will be binding unless in writing.</span></p>
    <p><strong>Statute of Limitations.</strong><span style="font-weight: 400;">&nbsp;You agree that regardless of any
            statute or law to the contrary, any claim or cause of action arising out of or related to use of our
            Application or these Terms must be filed within one (1) year after such claim or cause of action arose or be
            forever barred.</span></p>
    <p><strong>Applicable Law.</strong><span style="font-weight: 400;">&nbsp;These Terms will be construed in accordance
            with the laws of the United States of America and (to the extent not inconsistent with or preempted by
            federal law) the State of California, and the parties irrevocably consent to bring any action to enforce
            these Terms before an arbitration panel or before a court of competent jurisdiction in California if seeking
            interim or preliminary relief or enforcement of an arbitration award.</span></p>
    <p><span style="font-weight: 400;">If any part of these Terms is determined to be invalid or unenforceable pursuant
            to applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid,
            enforceable provision that most closely matches the intent of the original provision, and the remainder of
            the Terms shall continue in effect.</span></p>
    <p><strong>Headings.</strong><span style="font-weight: 400;">&nbsp;The provision titles in these Terms are for
            convenience only and have no legal or contractual effect. These Terms will not be construed against the
            drafter.</span></p>
    <p><strong>Notice.</strong><span style="font-weight: 400;">&nbsp;You hereby consent to receiving and transacting
            with us by electronic means. We may deliver notice to you by e-mail, posting a notice on the Application or
            any other method we choose and such notice will be effective on dispatch. If you give notice to us, it will
            be effective when received and you must use the following email address:</span></p>
    <p><span style="font-weight: 400;">info@edyou.com</span></p>
    <p><strong>Entire Agreement.</strong><span style="font-weight: 400;">&nbsp;These Terms (and all terms and conditions
            incorporated herein) constitute the entire agreement between you and EDYOU and govern your use of the
            Application and Services and supersede any prior agreements between you and EDYOU on the subject matter. You
            may also be subject to additional terms when you use certain EDYOU third party software, content, links, or
            websites. These Terms, and any rights or licenses granted hereunder, may not be assigned or delegated by
            you. These Terms, and any rights or licenses granted hereunder, may be assigned or delegated by EDYOU
            without restriction. These Terms bind and inure to the benefit of each party and the party&rsquo;s
            successors and permitted assigns. These Terms may not be modified by an oral statement by a representative
            of EDYOU. No agency, partnership, joint venture or employee-employer relationship is intended or created by
            these Terms. You agree to comply with all applicable laws in your use of the Application and Services. You
            agree that any agreements made by and between you and us in electronic form are as legally binding as if
            made in physical written form.</span></p>
    <p><strong>Notice to California Users.</strong><span style="font-weight: 400;">&nbsp;Under California Civil Code
            Section 1789.3, California website users are entitled to the following specific consumer rights notice: The
            Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
            Affairs may be contacted in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or
            by telephone at (800) 952-5210.</span></p>
    <p><strong>Notice to Users Outside the United States of America.</strong><span style="font-weight: 400;">&nbsp;The
            Application is controlled and offered by EDYOU from the United States of America. EDYOU makes no
            representations that the Application is appropriate for use in other locations. Those who access or use the
            Application from other locations do so at their own risk and are responsible for compliance with local law.
            You consent to the processing in the United States of America of information you provide to us.</span></p>
    <p><strong>[remainder of page intentionally blank]</strong></p>
    <p>&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Privacy Policy</strong></p>
    <p><span style="font-weight: 400;">This policy describes how EdYou Technologies Inc. collects, uses, and shares
            personal information.&nbsp;</span></p>
    <p><span style="font-weight: 400;">Types Of Information We Collect</span><span
            style="font-weight: 400;">.</span><span style="font-weight: 400;">[1]</span></p>
    <p><span style="font-weight: 400;">The following provides examples of the type of information that we collect from
            you and how we use that information.&nbsp;</span></p>


    <table style="width:446.4pt;margin-left:32.4pt;border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td
                    style="width: 98pt;border: 1pt solid windowtext;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Context</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Types of Data</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Primary Purpose for Collection&nbsp;<br>&nbsp;and Use
                            of Data</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Account Registration&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect your name, email address and contact
                            information when you create an account with us to use our Services.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in providing information
                            to you to perform the Services.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Subscriber Information&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect the name, email and contact information, of
                            our subscribers and their Companies and possibly their employees with whom we may interact.
                            &nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in contacting our
                            subscribers and communicating with them concerning normal administration.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Cookies and first party tracking</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use cookies and clear GIFs. &ldquo;Cookies&rdquo;
                            are small pieces of information that a website sends to a computer&rsquo;s hard drive while
                            a web site is viewed.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in making our website
                            operate efficiently.&nbsp;</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Cookies and Third Party Tracking</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We participate in behavior-based advertising, this
                            means that a third party uses technology (e.g., a cookie) to collect information about your
                            use of our website so that they can provide advertising about products and services tailored
                            to your interests on our website, or on other websites.&nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in engaging in
                            behavior-based advertising.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Email Interconnectivity</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">If you receive email from us, we use certain tools to
                            capture data related to when you open our message, click on any links or banners.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in understanding how you
                            interact with our communications to you.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Feedback/Support</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">If you provide us feedback or contact us for support
                            we will collect your name and e-mail address, as well as any other content that you send to
                            us, in order to reply.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in receiving, and acting
                            upon, your feedback or issues.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Mailing List</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">When you sign up for one of our mailing lists we
                            collect your email address or postal address. &nbsp; &nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in sharing information
                            about our organization.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Mobile Devices</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We collect information from your mobile device such as
                            unique identifying information broadcast from your device when visiting our website.</span>
                    </p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in identifying unique
                            visitors, and in understanding how users interact with us on their mobile devices.</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Payments&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect your name, billing address, shipping
                            address, e-mail address, phone number, and credit card number when you subscribe to our
                            Services.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use your information to perform our contract to
                            provide you with our Services.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Website interactions</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use technology to monitor how you interact with our
                            website. This may include which links you click on, or information that you type into our
                            online forms. &nbsp;This may also include information about your device or browser.</span>
                    </p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in understanding how you
                            interact with our website to better improve it, and to understand your preferences and
                            interests in order to select offerings that you might find most useful. &nbsp;We also have a
                            legitimate interest in detecting and preventing fraud.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Web logs</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect information, including your browser type,
                            operating system, Internet Protocol (IP) address (a number that is automatically assigned to
                            a computer when the Internet is used), domain name, click-activity, referring website,
                            and/or a date/time stamp for visitors.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in monitoring our
                            networks and the visitors to our website. &nbsp; &nbsp; Among other things, it helps us
                            understand which of our services is the most popular.</span></p>
                </td>
            </tr>
        </tbody>
    </table>

    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><span style="font-weight: 400;">In addition to the information that we collect from you directly, we may also
            receive information about you from other sources, including third parties, partners, our affiliates, or
            publicly available sources.&nbsp;</span></p>
    <p><strong>Use And Processing Of Information.</strong><strong>[2]</strong></p>
    <p><span style="font-weight: 400;">In addition to the purposes and uses described above, we use information in the
            following ways:</span></p>
    <ul>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To identify you when you visit our website.</span>
        </li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To provide services.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To improve our service offerings.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To streamline the donation or payment process.</span>
        </li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To conduct analytics.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To respond to inquiries related to support,
                employment opportunities, or other requests.&nbsp;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">To send promotional materials, including information
                relating to our services or promotions.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><span style="font-weight: 400;">For internal administrative purposes, as well as to
                manage our relationships.</span></li>
    </ul>
    <p><span style="font-weight: 400;">Although the sections above describe our primary purpose in collecting your
            information, in many situations we have more than one purpose.&nbsp; For example, if you complete an online
            donation we will collect your information to perform our contract with you, but we also collect your
            information because we have a legitimate interest in maintaining your information after your donation is
            complete so that we can quickly and easily respond to any questions about your donation.&nbsp; As a result,
            our collection and processing of your information is based in different contexts upon your consent, our need
            to perform a contract, our obligations under law, and/or our legitimate interest in conducting our
            business.</span></p>
    <p><strong>Sharing Of Information.</strong></p>
    <p><span style="font-weight: 400;">In addition to the specific situations discussed elsewhere in this policy, we
            disclose information in the following situations:</span></p>
    <ol>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Affiliates and Acquisitions. We may share information with our affiliates
                (e.g., parent organization, sister organization, joint ventures, or other organizations under common
                control).&nbsp; If another organization acquires, or plans to acquire, our Company, operations, or our
                assets, we will also share information with that organization, including at the negotiation
                stage.&nbsp;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Other Disclosures with Your Consent.&nbsp; We may ask if you would like us to
                share your information with other unaffiliated third parties who are not described elsewhere in this
                policy.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Other Disclosures without Your Consent. We may disclose information in
                response to subpoenas, warrants, or court orders, or in connection with any legal process, or to comply
                with relevant laws.&nbsp; We may also share your information in order to establish or exercise our
                rights, to defend against a legal claim, to investigate, prevent, or take action regarding possible
                illegal activities, suspected fraud, safety of person or property, or a violation of our policies, or to
                comply with your request for the shipment of products to or the provision of services by a third party
                intermediary.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span
                style="font-weight: 400;">Service Providers. We share your information with service providers.&nbsp;
                Among other things service providers help us to administer our website, conduct surveys, provide
                technical support, process payments, and assist in the fulfillment of Services.</span></li>
    </ol>
    <p><strong>Your Choices.</strong></p>
    <p><span style="font-weight: 400;">You can make the following choices regarding your personal information:</span>
    </p>
    <ol>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Access To Your Personal Information.</strong><span
                style="font-weight: 400;">&nbsp;You may request access to your personal information by contacting us at
                the address described below. If required by law, upon request, we will grant you reasonable access to
                the personal information that we have about you. &nbsp; Note that California residents may be entitled
                to ask us for a notice describing what categories of personal information (if any) we share with third
                parties or affiliates for direct marketing.&nbsp;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Changes To Your Personal Information.</strong><span
                style="font-weight: 400;">&nbsp;We rely on you to update and correct your personal information. Note
                that we may keep historical information in our backup files as permitted by law.&nbsp; If our website
                does not permit you to update or correct certain information contact us at the address described
                below.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Deletion Of Your Personal Information.</strong><span
                style="font-weight: 400;">&nbsp;Typically, we retain your personal information for the period necessary
                to fulfill the purposes outlined in this policy, unless a longer retention period is required or
                permitted by law.&nbsp; You may, however, request information about how long we keep a specific type of
                information, or request that we delete your personal information by contacting us at the address
                described below.&nbsp; If required by law we will grant a request to delete information, but you should
                note that in many situations we must keep your personal information to comply with our legal
                obligations, resolve disputes, enforce our agreements, or for another one of our business
                purposes.&nbsp;</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Objection to Certain Processing.</strong> <span style="font-weight: 400;">You
                may object to our use of your personal information by contacting us at the address described
                below.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Online Tracking</strong><span style="font-weight: 400;">.&nbsp; We do not
                currently recognize automated browser signals regarding tracking mechanisms, which may include &quot;Do
                Not Track&quot; instructions.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;</span><strong>Revocation Of Consent.</strong><span style="font-weight: 400;">&nbsp;If you
                revoke your consent for the processing of personal information then we may no longer be able to provide
                you services. In some cases, we may limit or deny your request to revoke consent if the law permits or
                requires us to do so, or if we are unable to adequately verify your identity. You may revoke consent to
                processing (where such processing is based upon consent) by contacting us at the address described
                below.</span></li>
    </ol>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><strong>How We Protect Personal Information</strong></p>
    <p><span style="font-weight: 400;">No method of transmission over the Internet, or method of electronic storage, is
            fully secure. While we use reasonable efforts to protect your personal information from unauthorized access,
            use, or disclosure, we cannot guarantee the security of your personal information. In the event that we are
            required by law to inform you of a breach to your personal information we may notify you electronically, in
            writing, or by telephone, if permitted to do so by law.</span></p>
    <p><span style="font-weight: 400;">Our website permits you to create an account.&nbsp; When you do you will be
            prompted to create a password.&nbsp; You are responsible for maintaining the confidentiality of your
            password, and you are responsible for any access to or use of your account by someone else that has obtained
            your password, whether or not such access or use has been authorized by you.&nbsp; You should notify us of
            any unauthorized use of your password or account.</span></p>
    <p><strong>California Privacy Act</strong></p>
    <p><span style="font-weight: 400;">Under California law, residents of California are permitted to request details on
            how their information is shared with third parties for direct marketing purposes. If you are a California
            resident and would like to make a request, please contact us at info@edyou.com.&nbsp;</span></p>
    <p><strong>Children&rsquo;s Privacy</strong></p>
    <p><span style="font-weight: 400;">We do not intentionally collect any personal information from children under the
            age of 13. If you are under the age of 13, you can look at our websites, but you should not make a purchase,
            register, or submit personal information to us.&nbsp; If you feel that we have collected data on a child,
            please reach out to us at info@edyou.com so that we can take appropriate action.</span></p>
    <p><strong>Users Outside the United States</strong><strong>&nbsp;</strong></p>
    <p><span style="font-weight: 400;">If you are a non-U.S. user of the Application, by visiting the Application and
            providing us with data, you understand and agree that the data you provide to us may be processed for the
            purposes listed in this Policy. You also understand and agree that the data you provide to us may be
            processed in the United States. U.S. laws regarding the processing of data may be less stringent than the
            laws of your country. By providing your data, you consent to this processing.</span></p>
    <p><span style="font-weight: 400;">Residents of the European Economic Area</span></p>
    <p><span style="font-weight: 400;">Upon request, residents of the EEA have the right to access the information we
            process about you, and to request that we correct, modify, or erase that information. You also have the
            right to opt out of or restrict certain types of processing subject to applicable legal restrictions. If you
            have any questions or you would like to exercise any of these rights, please reach out to us at
            info@edyou.com.&nbsp; We may take reasonable steps to verify your identity before granting access or making
            corrections.</span></p>
    <p><strong>Miscellaneous</strong></p>
    <p><span style="font-weight: 400;">The following additional information relates to our privacy practices:</span></p>
    <ul>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><strong>Transmission Of Information
                To Other Countries.</strong><span style="font-weight: 400;">&nbsp;As a Company located in the United
                States, we process personal information in the United States.&nbsp; If you provide your personal
                information to us your information will be processed in the United States where privacy laws may be less
                stringent than the laws in your country.&nbsp; By submitting your personal information to us you agree
                to the transfer, storage and processing of your information in a country other than your country of
                residence including the United States.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><strong>Third Party
                Applications/Websites.</strong><span style="font-weight: 400;">&nbsp;We have no control over the privacy
                practices of websites or applications that we do not own.</span></li>
        <li><span style="font-weight: 400;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span><strong>Changes To This Privacy
                Policy.</strong><span style="font-weight: 400;">&nbsp;We may change our privacy policy and practices
                over time.&nbsp; To the extent that our policy changes in a material way, the policy that was in place
                at the time that you submitted personal information to us will generally govern that information unless
                we receive your consent to the new privacy policy.&nbsp;&nbsp;</span></li>
    </ul>
    <p><strong>Contact Information</strong><strong>.</strong><span style="font-weight: 400;">&nbsp; If you have any
            questions, comments, or complaints concerning our privacy practices please contact us at the appropriate
            address below.&nbsp; We will attempt to respond to your requests and to provide you with additional
            privacy-related information.</span></p>
    <p><span style="font-weight: 400;">info@edyou.com</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><span style="font-weight: 400;">EdYou Privacy Notice for California Residents</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Effective Date: March 21, 2023</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">This Privacy Notice for California Residents (this &ldquo;Notice&rdquo;)
            supplements the information contained in EdYou Privacy Policy (the &ldquo;Policy&rdquo;) and applies solely
            to visitors, users, and others who reside in the State of California (&ldquo;Users&rdquo; or
            &ldquo;you&rdquo;) who visit edyou.com (the &ldquo;Application&rdquo;) or use the Services. We adopt this
            notice to comply with the California Consumer Privacy Act of 2018 (the &ldquo;CCPA&rdquo;) and any terms
            defined in the CCPA have the same meaning when used in this Notice.</span></p>
    <p>&nbsp;</p>
    <ol>
        <li><span style="font-weight: 400;">&nbsp;Information We Collect</span></li>
    </ol>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Our Application collects personal information or &ldquo;Personal
            Information,&rdquo; as further defined in Section 2 of the Policy. For purposes of this Notice,
            &ldquo;Personal Information&rdquo; also includes information that identifies, relates to, describes,
            references, is reasonably capable of being associated with, or could reasonably be linked, directly or
            indirectly, with a particular consumer, household, or device (&ldquo;Personal Information&rdquo;). Personal
            Information does not include: (i) Publicly available information from government records; (ii) Deidentified
            or aggregated consumer information; and (iii) Information excluded from the CCPA&rsquo;s scope, like: (a)
            health or medical information covered by the Health Insurance Portability and Accountability Act of 1996
            (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; (b)
            personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting
            Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and
            the Driver&rsquo;s Privacy Protection Act of 1994.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">In particular, our Application has collected the following categories of Personal
            Information from its Users within the last twelve (12) months:</span></p>
    <p>&nbsp;</p>

    <table style="width:446.4pt;margin-left:-.25pt;border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td
                    style="width: 98pt;border: 1pt solid windowtext;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Context</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Types of Data</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:center;'>
                        <span style="font-size:15px;color:black;">Primary Purpose for Collection&nbsp;<br>&nbsp;and Use
                            of Data</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Account Registration&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect your name, email address and contact
                            information when you create an account with us to use our Services.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 57.1pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in providing information
                            to you to perform the Services.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Subscriber Information&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect the name, email and contact information, of
                            our subscribers and their Companies and possibly their employees with whom we may interact.
                            &nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in contacting our
                            subscribers and communicating with them concerning normal administration.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Cookies and first party tracking</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use cookies and clear GIFs. &ldquo;Cookies&rdquo;
                            are small pieces of information that a website sends to a computer&rsquo;s hard drive while
                            a web site is viewed.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in making our website
                            operate efficiently.&nbsp;</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Cookies and Third Party Tracking</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We participate in behavior-based advertising, this
                            means that a third party uses technology (e.g., a cookie) to collect information about your
                            use of our website so that they can provide advertising about products and services tailored
                            to your interests on our website, or on other websites.&nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in engaging in
                            behavior-based advertising.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Email Interconnectivity</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">If you receive email from us, we use certain tools to
                            capture data related to when you open our message, click on any links or banners.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in understanding how you
                            interact with our communications to you.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Feedback/Support</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">If you provide us feedback or contact us for support
                            we will collect your name and e-mail address, as well as any other content that you send to
                            us, in order to reply.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in receiving, and acting
                            upon, your feedback or issues.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Mailing List</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">When you sign up for one of our mailing lists we
                            collect your email address or postal address. &nbsp; &nbsp;</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in sharing information
                            about our organization.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Mobile Devices</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We collect information from your mobile device such as
                            unique identifying information broadcast from your device when visiting our website.</span>
                    </p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-indent:0cm;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in identifying unique
                            visitors, and in understanding how users interact with us on their mobile devices.</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Payments&nbsp;</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect your name, billing address, shipping
                            address, e-mail address, phone number, and credit card number when you subscribe to our
                            Services.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use your information to perform our contract to
                            provide you with our Services.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Website interactions</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We use technology to monitor how you interact with our
                            website. This may include which links you click on, or information that you type into our
                            online forms. &nbsp;This may also include information about your device or browser.</span>
                    </p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in understanding how you
                            interact with our website to better improve it, and to understand your preferences and
                            interests in order to select offerings that you might find most useful. &nbsp;We also have a
                            legitimate interest in detecting and preventing fraud.</span></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 98pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:3.0pt;text-align:left;'>
                        <span style="font-size:15px;color:black;">Web logs</span></p>
                </td>
                <td
                    style="width: 161.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We collect information, including your browser type,
                            operating system, Internet Protocol (IP) address (a number that is automatically assigned to
                            a computer when the Internet is used), domain name, click-activity, referring website,
                            and/or a date/time stamp for visitors.</span></p>
                </td>
                <td
                    style="width: 187.1pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p
                        style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Garamond","serif";margin-top:3.0pt;margin-bottom:  3.0pt;text-align:justify;'>
                        <span style="font-size:15px;color:black;">We have a legitimate interest in monitoring our
                            networks and the visitors to our website. &nbsp; &nbsp; Among other things, it helps us
                            understand which of our services is the most popular.</span></p>
                </td>
            </tr>
        </tbody>
    </table>

    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We obtain the Personal Information listed above from the following categories of
            sources:</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Directly from you. For example, from forms you complete or products and services
            you purchase.</span></p>
    <p><span style="font-weight: 400;">Indirectly from you. For example, from observing your actions on our Application
            through cookies or other tracking mechanisms.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Use of Personal Information</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We may use or disclose the Personal Information we collect for business purposes
            set forth in the Policy.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Sharing Personal Information</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We may disclose your Personal Information to a third party for business purposes
            as set forth in the Policy. When we disclose Personal Information to a third party for a business purpose,
            we enter into a contract that describes the purpose for which the disclosure is being made and requires the
            recipient to both keep that Personal Information confidential and not use it for any purpose except for the
            purposes specified in the contract. The CCPA prohibits third parties who purchase the Personal Information
            that we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of
            further sales.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Disclosures of Personal Information for a Business Purpose</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">In the preceding twelve (12) months, we have disclosed the following categories
            of Personal Information for a business purpose:</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Category A: Identifiers; Category B: California Customer Records personal
            information categories;</span></p>
    <p><span style="font-weight: 400;">Category D: Commercial information; Category F: Internet or other similar network
            activity; and Category G: Geolocation data.</span></p>
    <p>&nbsp;</p>
    <ol start="2">
        <li><span style="font-weight: 400;">&nbsp;Your Rights and Choices</span></li>
    </ol>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">The CCPA provides California residents with specific rights regarding their
            Personal Information. This section describes your CCPA rights and explains how to exercise those
            rights.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Access to Specific Information and Data Portability Rights</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">You have the right to request that we disclose certain information to you about
            our collection and use of your Personal Information over the past 12 months. Once we receive and confirm
            your verifiable consumer request, to the extent it is possible to associate your request with any Personal
            Information that we possess, we will disclose to you: (i) The categories of Personal Information we
            collected about you; (ii) The categories of sources for the Personal Information we collected about you;
            (iii) Our business or commercial purpose for collecting or selling that Personal Information; (iv) The
            categories of third parties with whom we share that Personal Information. The specific pieces of Personal
            Information we collected about you (also called a data portability request); (iv) The specific pieces of
            Personal Information we collected about you (also called a data portability request); and (v) If we sold or
            disclosed your Personal Information for a business purpose, two separate lists disclosing: (a) sales,
            identifying the Personal Information categories that each category of recipient purchased; and (b)
            disclosures for a business purpose, identifying the Personal Information categories that each category of
            recipient obtained.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Deletion Request Rights</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">You have the right to request that we delete any of your Personal Information
            that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your
            verifiable consumer request, to the extent it is possible to associate your request with any Personal
            Information that we possess, we will delete (and direct our service providers to delete) your Personal
            Information from our records, unless an exception applies. We may deny your deletion request if retaining
            the information is necessary for us or our service provider(s) to: (i) Complete the transaction for which we
            collected the Personal Information, provide a good or service that you requested, take actions reasonably
            anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written
            warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with
            you;</span></p>
    <p><span style="font-weight: 400;">Detect security incidents, protect against malicious, deceptive, fraudulent, or
            illegal activity, or prosecute those responsible for such activities; (ii) Debug products to identify and
            repair errors that impair existing intended functionality; (iii) Exercise free speech, ensure the right of
            another consumer to exercise their free speech rights, or exercise another right provided for by law; (iv)
            Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.); (v)
            Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
            that adheres to all other applicable ethics and privacy laws, when the information&rsquo;s deletion may
            likely render impossible or seriously impair the research&rsquo;s achievement, if you previously provided
            informed consent; (vi) Enable solely internal uses that are reasonably aligned with consumer expectations
            based on your relationship with us; (vii) Comply with a legal obligation; or (viii) Make other internal and
            lawful uses of that information that are compatible with the context in which you provided it.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Exercising Access, Data Portability, and Deletion Rights</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">To exercise the access, data portability, and deletion rights described above,
            please submit a verifiable consumer request to us by either:</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Emailing us at info@edyou.com with &ldquo;Attn: CCPA&rdquo; in the subject line;
            or</span></p>
    <p><span style="font-weight: 400;">Write to us at EdYou, 11766 Wilshire Blvd, Suite 405, Los Angeles, CA
            90025.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Only you, or someone legally authorized to act on your behalf, may make a
            verifiable consumer request related to your Personal Information. You may also make a verifiable consumer
            request on behalf of your minor child. You may only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer request must:</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Provide sufficient information that allows us to reasonably verify you are the
            person about whom we collected Personal Information or an authorized representative; and</span></p>
    <p><span style="font-weight: 400;">Describe your request with sufficient detail that allows us to properly
            understand, evaluate, and respond to it.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We cannot respond to your request or provide you with Personal Information if we
            cannot verify your identity or authority to make the request and confirm the Personal Information relates to
            you. We will only use Personal Information provided in a verifiable consumer request to verify the
            requestor&rsquo;s identity and/or authority to make the request.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Response Timing and Format</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We try to respond to verifiable consumer requests within sixty days of receipt.
            If we require more time, we will inform you of the reason and extension period in writing. We will deliver
            our written response by mail or electronically, at your option. Any disclosures we provide will only cover
            the twelve (12) month period preceding receipt of the verifiable consumer request. The response we provide
            will also explain the reasons we cannot comply with a request, if applicable. For data portability requests,
            we will select a format to provide your Personal Information that is readily useable and should allow you to
            transmit the information from one entity to another entity without hindrance. We do not charge a fee to
            process or respond to a verifiable consumer request unless such a request is excessive, repetitive, or
            manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that
            decision and provide you with a cost estimate before completing your request.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Opt-Out and Opt-In Rights</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">If you are 16 years of age or older, you have the right to direct us to not sell
            your Personal Information at any time (the &ldquo;right to opt-out&rdquo;). We do not sell the Personal
            Information of Users we actually know are less than 16 years of age, unless we receive affirmative
            authorization (the &ldquo;right to opt-in&rdquo;) from either the consumer who is at least 13 but not yet 16
            years of age, or the parent or guardian of a consumer less than 13 years of age. Users who opt-in to
            Personal Information sales may opt-out of future sales at any time. To exercise the right to opt-out, you
            (or your authorized representative) may submit a request to us by visiting the following Internet Web page
            link:</span><a href="https://edyou.com/">&nbsp;<span
                style="font-weight: 400;">https://edyou.com</span></a><span style="font-weight: 400;">.&nbsp; Once you
            make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal
            information sales. However, you may change your mind and opt back in to personal information sales at any
            time by emailing us at info@edyou.com with &ldquo;Attn: CCPA&rdquo; in the subject line or writing to us at
            EdYou, 11766 Wilshire Blvd, Suite 405, Los Angeles, CA 90025. You do not need to create an account with us
            to exercise your opt-out rights. We will only use Personal Information provided in an opt-out request to
            review and comply with the request.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Non-Discrimination</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We will not discriminate against you for exercising any of your CCPA rights.
            Unless permitted by the CCPA, we will not:</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Deny you goods or services;</span></p>
    <p><span style="font-weight: 400;">Charge you different prices or rates for goods or services, including through
            granting discounts or other benefits, or imposing penalties;</span></p>
    <p><span style="font-weight: 400;">Provide you a different level or quality of goods or services; or</span></p>
    <p><span style="font-weight: 400;">Suggest that you may receive a different price or rate for goods or services or a
            different level or quality of goods or services.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">However, we may offer you certain financial incentives permitted by the CCPA that
            can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer
            will reasonably relate to your Personal Information&rsquo;s value and contain written terms that describe
            the program&rsquo;s material aspects. Participation in a financial incentive program requires your prior opt
            in consent, which you may revoke at any time.</span></p>
    <p>&nbsp;</p>
    <ol start="3">
        <li><span style="font-weight: 400;">&nbsp;Other California Privacy Rights</span></li>
    </ol>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">California Civil Code Section 1798.83, also known as the &ldquo;Shine The
            Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a
            calendar year and free of charge, information about categories of personal information (if any) we disclosed
            to third parties for direct marketing purposes and the names and addresses of all third parties with which
            we shared personal information in the immediately preceding calendar year. If you are a California resident
            and would like to make such a request, please send an email to info@edyou.com with &ldquo;Attn: CCPA&rdquo;
            in the subject line or write to us at EdYou, 11766 Wilshire Blvd, Suite 405, Los Angeles, CA 90025. If you
            are under 18 years of age, reside in California, and have a registered account with the Application, you
            have the right to request removal of unwanted data that you publicly post on the Application. To request
            removal of such data, please contact us using the contact information provided and include the email address
            associated with your account and a statement that you reside in California. We will make sure that the data
            is not publicly displayed on the Application, but please be aware that the data may not be completely or
            comprehensively removed from our systems. To request any other changes or information about our collection,
            use or disclosure of your information, please email us at info@edyou.com with &ldquo;Attn: Privacy&rdquo; in
            the subject line or write to us at EdYou, 11766 Wilshire Blvd, Suite 405, Los Angeles, CA 90025. Third
            parties may keep track of your browsing activities across third party websites. California Business &amp;
            Professions Code Section 22575(b) provides that California residents are entitled to know whether we respond
            to &ldquo;Do Not Track&rdquo; browser signals. &ldquo;Do Not Track&rdquo; is a preference you can set in
            your web browser to let the sites you visit know that you do not want them collecting information about you.
            Our Application does not currently respond to &ldquo;Do Not Track&rdquo; settings. For further details
            regarding &ldquo;Do Not Track,&rdquo; visit donottrack.us.</span></p>
    <p>&nbsp;</p>
    <ol start="4">
        <li><span style="font-weight: 400;">&nbsp;Changes to Our Privacy Notice</span></li>
    </ol>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We reserve the right to amend this Notice at our discretion and at any time. When
            we amend this Notice, we will post the updated notice on the Application and update the notice&rsquo;s
            effective date. Your continued use of our Website following the posting of changes constitutes your
            acceptance of any such amendments.</span></p>
    <p><span style="font-weight: 400;">We use cookies</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We use cookies and other tracking technologies to improve your browsing
            experience on our website, to show you personalized content and targeted ads, to analyze our website
            traffic, and to understand where our visitors are coming from.</span></p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">[1]</span><span style="font-weight: 400;">&nbsp;@Please review this list to
            confirm it accurately captures the info Edyou&rsquo;s website collects.</span></p>
    <p><span style="font-weight: 400;">[2]</span><span style="font-weight: 400;">&nbsp;@Please review this list to
            confirm it accurately captures what EdYou does with the info it collects.</span></p>
    <p>&nbsp;</p>

</div>